import { calculateAnnulationInsurance } from "./calculateAnnulationInsurance";

export const calculateInsurances = (
  travellers: TravellerType[],
  rooms: RoomType[]
) => {
  let travelInsurances = 0;
  let annulationInsurances = 0;
  let annulationInsuranceTotal = 0;
  travellers.forEach((t) => {
    if (t.hasTravelInsurance) {
      travelInsurances++;
    }
    if (t.hasAnnulationInsurance) {
      // get room
      const room = rooms.find((x) => x.id === t.room);
      if (room) {
        annulationInsuranceTotal += calculateAnnulationInsurance(room.price);
      }
      annulationInsurances++;
    }
  });
  return { travelInsurances, annulationInsurances, annulationInsuranceTotal };
};
