import styled from "styled-components";
import { CheckmarkIcon } from "@travelquest/web-components";

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  cursor: pointer;
`;

export const Checked = styled(CheckmarkIcon)`
  border-radius: 3.33px;
  background-color: #2f1f5d;
  color: white;
  padding: 3px;
  min-width: 13px;
  font-size: 13px;
  margin-right: 8px;
`;
export const CheckBoxName = styled.span`
  color: #231747;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
`;
export const CheckBoxBox = styled.div`
  min-width: 35px;
`;

export const Unchecked = styled.div`
  margin-right: 8px;
  height: 15px;
  min-width: 15px;
  box-sizing: border-box;
  width: 15px;
  border: 1px solid #dedce3;
  border-radius: 3.33px;
  background-color: #ffffff;
`;
