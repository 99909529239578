import React, { useState } from "react";
import { Button } from "@travelquest/web-components";
import { Link } from "react-router-dom";
import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
} from "../../components";
import { Layout, Header, Buttons } from "./TravellerInformation.styled";
import { Traveller } from "./Traveller";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { UseUpdateTraveller } from "../../hooks/UseUpdateTraveller";
import { calculateAge } from "../../helpers";

const requiredFields = [
  "firstName",
  "lastName",
  "gender",
  "dateOfBirth",
  "country",
  "street",
  "city",
  "zip",
  "email",
  "phone",
];

const requiredUnderAgeFields = [
  "parentFirstName",
  "parentLastName",
  "parentEmail",
  "parentPhone",
];

const areTravellersValid = (travellers: TravellerType[], country: string) => {
  const valids: string[] = [];
  travellers.forEach((traveller, i) => {
    let _required = [...requiredFields];

    if (country === "BE" && traveller.country === "BE") {
      _required.push("registryNr");
    }

    let valid = true;
    const age = calculateAge(traveller.dateOfBirth);
    let __required = [..._required];

    if (age < 18) {
      __required = __required.concat(requiredUnderAgeFields);
      if (country === "BE" && traveller.country === "BE") {
        __required.push("parentRegistryNr");
      }
    } else {
      __required.push("emergencyPhone");
    }
    __required.forEach((key) => {
      const value: any | null = traveller[key as keyof TravellerType];
      if (!value || value === "") {
        valid = false;
      }
    });

    if (valid) {
      valids.push(traveller.id);
    }
  });
  return valids;
};

export const TravellerInformation = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;

  const { booking, loading } = UseGetBooking(bookingId);
  const [openTraveller, setOpenTraveller] = useState<number | null>(0);
  const { updateTraveller } = UseUpdateTraveller();

  let travellerCount = 0;

  booking?.rooms.forEach((r) => {
    travellerCount = travellerCount + (r.size + 1);
  });

  const travellers = [];
  for (let i = 0; i < travellerCount; i++) {
    travellers.push({});
  }

  const validTravellers = areTravellersValid(
    booking?.travellers || [],
    brand.country
  );

  const nextTraveller = (i: number) => {
    if (i < travellerCount) {
      setOpenTraveller(i + 1);
    } else {
      setOpenTraveller(null);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Page>
      <Steps current={2} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <Card>
          <Header>Gegevens van de reizigers</Header>
          {booking?.travellers?.map((t, i) => (
            <Traveller
              key={t.id}
              index={i}
              isOpen={i === openTraveller}
              valid={validTravellers.includes(t.id)}
              defaultValues={t}
              openHandler={setOpenTraveller}
              update={(values) => {
                updateTraveller({
                  id: t.id,
                  values,
                  bookingId,
                  initTravellers: booking?.travellers,
                });
              }}
              nextTraveller={nextTraveller}
            />
          ))}
        </Card>
        <Buttons>
          <Link to={`/step2?id=${bookingId}&brand=${brand.code}`}>
            <Button color="#655C7E">Vorige stap</Button>
          </Link>
          {validTravellers.length === travellers.length ? (
            <a href={`/step4?id=${bookingId}&brand=${brand.code}`}>
              <Button color={brand.color}>Volgende stap</Button>
            </a>
          ) : (
            <Button color="#655C7E">Volgende stap</Button>
          )}
        </Buttons>
      </Layout>
    </Page>
  );
};
