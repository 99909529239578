import { unixToDate } from "./unixHelpers";

export const getPriceForAddon = (
  pricing: AddonPricing[],
  defaultPrice: number,
  bookingStart: number
): number => {
  let price = defaultPrice || 0;

  const bookingStartDate = unixToDate(bookingStart);

  pricing?.forEach((priceObject: any) => {
    const start = unixToDate(priceObject.periodStart);
    const end = unixToDate(priceObject.periodEnd);
    if (bookingStartDate.isBetween(start, end, "day", "[]")) {
      price = priceObject.price;
    }
  });

  return price;
};
