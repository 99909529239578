import React, { ReactNode } from "react";
import {
  CheckBoxBox,
  CheckBoxContainer,
  Checked,
  Unchecked,
  CheckBoxName,
} from "./Checkbox.styled";

type Props = {
  checked: boolean;
  children: ReactNode;
  onClick: () => void;
};

export const Checkbox = ({ checked, children, onClick }: Props) => {
  return (
    <CheckBoxContainer onClick={onClick}>
      <CheckBoxBox>{checked ? <Checked /> : <Unchecked />}</CheckBoxBox>
      <CheckBoxName>{children}</CheckBoxName>
    </CheckBoxContainer>
  );
};
