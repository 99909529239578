import styled from "styled-components";
import { media } from "../../helpers";
import { Dropdown } from "../../components";

export const Layout = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 3fr 5fr;
  ${media.tablet`
  grid-template-columns: 1fr;
`}
  p {
    background-color: #f8f8f9;
    width: 100%;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
  }
`;

export const Header = styled.div`
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding: 20px;
  background-color: #231747;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const LabelAndDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 200px;
  justify-content: center;
  align-items: center;
`;

export const StyledLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  max-width: 200px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
`;
