import styled from "styled-components";
import { CheckmarkIcon } from "@travelquest/web-components";
import { Dropdown } from "../../../components";

export const Header = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  cursor: pointer;
  background-color: #f8f8f9;
  width: 100%;
  padding: 24px 15px;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  padding: 16px 20px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  color: #231747;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
`;

export const RoomOccupants = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const ValidIcon = styled(CheckmarkIcon)`
  color: green;
  margin-left: 10px;
`;

export const DropdownField = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const StyledDropdown = styled(Dropdown)`
  min-width: 100px;
  margin-right: 20px;
`;

export const Traveller = styled.div`
  background-color: #231747;
  color: white;
  width: fit-content;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
