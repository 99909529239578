import React from "react";
import { StarFilledIcon } from "@travelquest/web-components";
import { Star, HalfStar, Container } from "./Stars.styled";

type StarsProps = {
  count: number;
  className?: string;
};

export const Stars = ({ count = 0, className }: StarsProps) => {
  const stars = [];
  for (let i = 0; i < count; i++) {
    if (i < count && count < i + 1) {
      stars.push(
        <HalfStar key={`star-${i}`}>
          <StarFilledIcon />
        </HalfStar>
      );
    } else {
      stars.push(
        <Star key={`star-${i}`}>
          <StarFilledIcon />
        </Star>
      );
    }
  }
  return <Container className={className}>{stars}</Container>;
};
