import styled from "styled-components";
import { media } from "../../helpers/mediaQueries";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  ${media.tablet`
  width: 100%;
  padding: 0 10px;
  `};
`;
