import React from "react";
import { MinIcon, PlusMathIcon } from "@travelquest/web-components";
import {
  TravelerContent,
  TravellerObject,
  TravellerHeader,
} from "./Traveller.styled";
import { Checkbox } from "../../../components";
import { getPriceForAddon } from "../../../helpers";

type Props = {
  isOpen: boolean;
  setOpen: (index: number | null) => void;
  update: (values: Record<string, any>) => void;
  index: number;
  finalFilterAddons: AddonType[];
  travellerData: TravellerType;
  selectedAddons: any;
  updateTravellerAddons: (travelerId: string, addons: any) => void;
  period: PeriodType;
};

const updateArray = (toBeAdded: boolean, value: any, array: any[]) => {
  const _array = [...array];
  if (toBeAdded) {
    _array.push(value);
  } else {
    let index = -1;
    if (value.id) {
      index = _array.findIndex((x) => x.id === value.id);
    } else {
      index = _array.findIndex((x) => x === value);
    }
    _array.splice(index, 1);
  }

  return _array;
};

export const Traveller: React.FC<Props> = ({
  isOpen,
  setOpen,
  index,
  finalFilterAddons,
  travellerData,
  update,
  selectedAddons,
  updateTravellerAddons,
  period,
}) => {
  const { firstName, lastName } = travellerData;
  return (
    <TravellerObject>
      <TravellerHeader onClick={() => setOpen(isOpen ? null : index)}>
        {firstName} {lastName}
        {isOpen ? <MinIcon /> : <PlusMathIcon />}
      </TravellerHeader>
      {isOpen && (
        <TravelerContent>
          {finalFilterAddons?.map((addon) => (
            <Checkbox
              id={addon.id}
              initialValue={selectedAddons?.[travellerData?.id]?.includes(
                addon.id
              )}
              callback={(bool) => {
                updateTravellerAddons(
                  travellerData?.id,
                  updateArray(
                    bool,
                    addon.id,
                    selectedAddons?.[travellerData?.id] || []
                  )
                );
                update({
                  addons: updateArray(
                    bool,
                    addon.id,
                    selectedAddons?.[travellerData?.id] || []
                  ),
                });
              }}
              key={addon.id}
              price={getPriceForAddon(
                addon.pricing,
                addon.defaultPrice,
                period?.start
              )}
              disabled={false}
            >
              {addon.name.nlbe}
            </Checkbox>
          ))}
        </TravelerContent>
      )}
    </TravellerObject>
  );
};
