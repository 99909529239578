import styled from "styled-components";
import { Button } from "@travelquest/web-components";

export const StyledImage = styled.img`
  width: 300px;
`;

export const Text = styled.div`
  margin-bottom: 30px;
  width: 50%;
  color: rgba(35, 23, 71, 0.6);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
`;

export const Title = styled.h1`
  color: #231747;
  font-size: 44px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
