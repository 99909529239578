import React from "react";
import { Formik } from "formik";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import customParseFormat from "dayjs/plugin/customParseFormat";
import { Button } from "@travelquest/web-components";
import { Container, ParentField, Buttons } from "./TravellerForm.styled";
import { FormField } from "./FormField";
import { ContextType, useAppState } from "../../store";

dayjs.extend(customParseFormat);

const calculateAge = (date: string) =>
  dayjs().diff(dayjs(date, "DD/MM/YYYY"), "year");

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type TravellerFormProps = {
  nextTraveller: () => void;
  update: (values: Record<string, any>) => void;
  defaultValues: any;
  onValuesChangeCallback: any;
};

export const TravellerForm = ({
  nextTraveller,
  update,
  defaultValues,
  onValuesChangeCallback,
}: TravellerFormProps) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;

  const { t } = useTranslation("traveller");

  return (
    <Container>
      <Formik
        initialValues={{
          firstName: defaultValues?.firstName || "",
          lastName: defaultValues?.lastName || "",
          parentFirstName: defaultValues?.parentFirstName || "",
          parentLastName: defaultValues?.parentLastName || "",
          parentEmail: defaultValues?.parentEmail || "",
          extraEmail: defaultValues?.extraEmail || "",
          parentPhone: defaultValues?.parentPhone || "",
          parentRegistryNr: defaultValues?.parentRegistryNr || "",
          email: defaultValues?.email || "",
          phone: defaultValues?.phone || "",
          emergencyPhone: defaultValues?.emergencyPhone || "",
          gender: defaultValues?.gender || "male",
          dateOfBirth: defaultValues?.dateOfBirth || "",
          registryNr: defaultValues?.registryNr || "",
          street: defaultValues?.street || "",
          houseAddition: defaultValues?.houseAddition || "",
          city: defaultValues?.city || "",
          zip: defaultValues?.zip || "",
          country: defaultValues?.country || brand.country,
        }}
        validate={(values: Record<string, any>) => {
          const errors: Record<string, string> = {};
          const age: number = calculateAge(values.dateOfBirth);
          let required = [
            "firstName",
            "lastName",
            "gender",
            "dateOfBirth",
            "country",
            "street",
            "city",
            "zip",
            "email",
            "phone",
            "emergencyPhone",
          ];

          if (values.country === "BE") {
            required.push("registryNr");
          } else {
            required = required.filter((field) => field !== "registryNr");
          }

          Object.keys(values).forEach((key) => {
            if (required.includes(key) && !values[key]) {
              errors[key] = "Dit veld is verplicht";
            }
          });

          if (!/^[0-9-.]*$/.test(values.registryNr)) {
            errors.registryNr = "Ongeldig rijksregister nummer";
          }

          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Ongeldig emailadres";
          }

          if (values.phone === values.emergencyPhone) {
            errors.emergencyPhone =
              "Dit nummer mag niet hetzelfde zijn als de reiziger.";
          }

          const phoneRegex = /^[0-9+]*$/;

          if (!phoneRegex.test(values.phone)) {
            errors.phone = "Ongeldig telefoon nummer";
          }

          if (!phoneRegex.test(values.emergencyPhone)) {
            errors.emergencyPhone = "Ongeldig telefoon nummer";
          }

          if (values.phone === values.parentPhone) {
            errors.emergencyPhone =
              "Dit nummer mag niet hetzelfde zijn als de reiziger.";
          }

          if (
            !(
              dayjs(values.dateOfBirth, "DD/MM/YYYY", true).format(
                "DD/MM/YYYY"
              ) === values.dateOfBirth
            )
          ) {
            errors.dateOfBirth = "Ongeldige datum";
          } else {
            // valid date of birth, check for parent required
            if (age < 18) {
              const parentFields = [
                "parentEmail",
                "parentPhone",
                "parentLastName",
                "parentFirstName",
              ];

              if (values.email === values.parentEmail) {
                errors.parentEmail =
                  "Deze email mag niet hetzelfde zijn als de reiziger.";
                errors.email =
                  "Deze email mag niet hetzelfde zijn als de reiziger.";
              }

              if (brand.country === "BE") {
                parentFields.push("parentRegistryNr");
                if (!/^[0-9-.]*$/.test(values.parentRegistryNr)) {
                  errors.parentRegistryNr = "Ongeldig rijksregister nummer";
                }
              }

              parentFields.forEach((key) => {
                if (parentFields.includes(key) && !values[key]) {
                  errors[key] = "Dit veld is verplicht";
                }
              });

              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.parentEmail
                )
              ) {
                errors.parentEmail = "Ongeldig emailadres";
              }
            }
          }
          onValuesChangeCallback(values);
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <div>
            <FormField
              title="Voornaam"
              error={errors.firstName}
              value={values.firstName}
              name="firstName"
              handleChange={handleChange}
            />
            <FormField
              title={capitalizeFirstLetter(t("last name"))}
              error={errors.lastName}
              value={values.lastName}
              name="lastName"
              handleChange={handleChange}
            />
            <FormField
              title="Geslacht"
              type="radio"
              value={values.gender}
              error={errors.gender}
              name="gender"
              handleChange={(v) => setFieldValue("gender", v)}
            />
            <FormField
              title="Geboortedatum"
              type="date"
              error={errors.dateOfBirth}
              value={values.dateOfBirth}
              name="dateOfBirth"
              handleChange={(v) => setFieldValue("dateOfBirth", v)}
            />
            <FormField
              type="select"
              title={capitalizeFirstLetter(t("country"))}
              error={errors.country}
              value={values.country}
              name="country"
              options={[
                { value: "BE", label: "België" },
                { value: "NL", label: "Nederland" },
              ]}
              handleChange={(v) => setFieldValue("country", v)}
            />
            {calculateAge(values.dateOfBirth) < 18 && (
              <ParentField>
                <p>
                  Indien jonger dan 18 jaar moet je de gegevens van een voogd of
                  ouder invullen:
                </p>
                <FormField
                  title="Voornaam ouder/voogd"
                  error={errors.parentFirstName}
                  value={values.parentFirstName}
                  name="parentFirstName"
                  handleChange={handleChange}
                />
                <FormField
                  title="Achternaam ouder/voogd"
                  error={errors.parentLastName}
                  value={values.parentLastName}
                  name="parentLastName"
                  handleChange={handleChange}
                />
                <FormField
                  title={`${capitalizeFirstLetter(
                    t("phoneNumber")
                  )} ouder/voogd`}
                  value={values.parentPhone}
                  error={errors.parentPhone}
                  name="parentPhone"
                  handleChange={handleChange}
                />
                <FormField
                  title="E-mail ouder/voogd"
                  customPlaceholder="E-mail ouder/voogd"
                  value={values.parentEmail}
                  error={errors.parentEmail}
                  name="parentEmail"
                  handleChange={handleChange}
                />
                <FormField
                  title="E-mail 2e ouder/voogd"
                  customPlaceholder="E-mail 2e ouder/voogd (optioneel)"
                  value={values.extraEmail}
                  error={errors.extraEmail}
                  name="extraEmail"
                  handleChange={handleChange}
                />
                {brand.country === "BE" && values.country === "BE" && (
                  <FormField
                    title="Rijksregister- of identificatienummer ouder/voogd"
                    type="registryNumber"
                    error={errors.parentRegistryNr}
                    value={values.parentRegistryNr}
                    name="parentRegistryNr"
                    handleChange={(v) => setFieldValue("parentRegistryNr", v)}
                  />
                )}
              </ParentField>
            )}
            {brand.country === "BE" && values.country === "BE" && (
              <FormField
                title="Rijksregisternummer"
                type={
                  values.country === "NL"
                    ? "registryNumberNL"
                    : "registryNumber"
                }
                error={errors.registryNr}
                value={values.registryNr}
                name="registryNr"
                handleChange={(v) => setFieldValue("registryNr", v)}
              />
            )}
            <FormField
              title="Straat + huisnummer"
              error={errors.street}
              value={values.street}
              name="street"
              handleChange={handleChange}
            />
            <FormField
              title="Brievenbus"
              customPlaceholder="Brievenbus (optioneel)"
              error={errors.houseAddition}
              value={values.houseAddition}
              name="houseAddition"
              handleChange={handleChange}
            />
            <FormField
              title="Stad"
              error={errors.city}
              value={values.city}
              name="city"
              handleChange={handleChange}
            />
            <FormField
              title="Postcode"
              error={errors.zip}
              value={values.zip}
              name="zip"
              handleChange={handleChange}
            />
            <FormField
              title="E-mail reiziger"
              value={values.email}
              error={errors.email}
              name="email"
              handleChange={handleChange}
            />
            {calculateAge(values.dateOfBirth) > 18 && (
              <FormField
                title="E-mail ouders/voogden (optioneel)"
                value={values.extraEmail}
                error={errors.extraEmail}
                name="extraEmail"
                handleChange={handleChange}
              />
            )}
            <FormField
              title={`${capitalizeFirstLetter(t("phoneNumber"))} reiziger`}
              value={values.phone}
              error={errors.phone}
              name="phone"
              handleChange={handleChange}
            />
            <FormField
              title={`${capitalizeFirstLetter(t("phoneNumber"))} noodcontact`}
              value={values.emergencyPhone}
              error={errors.emergencyPhone}
              name="emergencyPhone"
              handleChange={handleChange}
            />
            <Buttons>
              <Button
                color={brand.color}
                onClick={
                  Object.keys(errors).length === 0
                    ? () => {
                        update(values);
                        nextTraveller();
                      }
                    : () => console.log("error", errors)
                }
                /* @ts-ignore */
                disabled={
                  Object.keys(errors).length !== 0 || values?.firstName === ""
                }
              >
                Volgende
              </Button>
            </Buttons>
          </div>
        )}
      </Formik>
    </Container>
  );
};
