import React, { useState } from "react";
import {
  MinIcon,
  PlusMathIcon,
  CloseIcon,
  Button,
} from "@travelquest/web-components";

import {
  Traveller,
  Container,
  Content,
  Header,
  RoomOccupants,
  Title,
  DropdownField,
  ValidIcon,
  StyledDropdown,
} from "./Room.styled";
import { ContextType, useAppState } from "../../../store";

type RoomProps = {
  isOpen: boolean;
  setOpen: () => void;
  travellers: TravellerType[];
  size: number;
  id: string;
  availableTravellers: Partial<TravellerType>[];
  removeTraveller: (travellerId: string) => void;
  addTraveller: (travellerId: string, roomId: string) => void;
};

export const Room = ({
  isOpen,
  setOpen,
  addTraveller,
  id,
  removeTraveller,
  travellers,
  size,
  availableTravellers,
}: RoomProps) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  const [selected, setSelected] = useState(null);
  const valid = travellers?.length === size + 1;
  const travellerOptions = availableTravellers.map((x) => ({
    value: x.id,
    label: `${x.firstName} ${x.lastName}`,
  }));
  return (
    <Container>
      <Header onClick={setOpen}>
        <span>
          {`${size + 1}-persoonskamer`}
          {valid && <ValidIcon />}
        </span>
        {isOpen ? <MinIcon /> : <PlusMathIcon />}
      </Header>
      {isOpen && (
        <Content>
          <div>
            <Title>Voeg een reiziger toe</Title>
            <p>
              Hier kan je per kamer alle reizigers toevoegen. Indien er slechts
              één kamer of accommodatie is binnen de boeking, voeg je iedereen
              toe aan dezelfde 'kamer'. Natuurlijk kan je nog steeds tijdens je
              reis zelf kiezen met wie je graag in de kamer(s) wenst te liggen.
              Verblijven jullie in een villa of appartement? Dan is deze
              natuurlijk volledig van jullie groep, er worden hier dus geen
              kamers gedeeld met andere groepen.
            </p>
            <DropdownField>
              <StyledDropdown
                handleChange={(x) => setSelected(x)}
                options={travellerOptions}
              />
              {selected && !valid ? (
                <Button
                  color={brand.color}
                  onClick={() => addTraveller(selected, id)}
                >
                  Voeg toe
                </Button>
              ) : (
                <Button color="#655C7E">Voeg toe</Button>
              )}
            </DropdownField>

            <Title>
              Reizigers ({travellers?.length}/{size + 1})
            </Title>
            <RoomOccupants>
              {travellers?.map((x) => (
                <Traveller key={x.id} onClick={() => removeTraveller(x.id)}>
                  {x.firstName} {x.lastName}
                  <CloseIcon />
                </Traveller>
              ))}
            </RoomOccupants>
          </div>
        </Content>
      )}
    </Container>
  );
};
