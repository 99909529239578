import { unixToDate } from "./unixHelpers";

export const getPriceForExcursion = (
  pricing: any,
  defaultPrice: number,
  periodStart: number
): number => {
  const _periodStart = unixToDate(periodStart);
  let price = defaultPrice || 0;
  pricing?.forEach((priceObject: any) => {
    const start = unixToDate(priceObject.periodStart);
    const end = unixToDate(priceObject.periodEnd)
    if (_periodStart.isBetween(start,end, "day",'[]')) {
      price = priceObject.price;
    }
  });
  return price;
};
