import React, { useState } from "react";
import { Button } from "@travelquest/web-components";
import { Link } from "react-router-dom";

import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
} from "../../components";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { UseUpdateBookingHook } from "../../hooks/UseUpdateBookingHook";
import {
  StyledDropdown,
  Buttons,
  Header,
  Layout,
  TravellerContent,
  TravellerObject,
} from "./RoomAssignment.styled";
import { Room } from "./Room";
import { UseUpdateTraveller } from "../../hooks/UseUpdateTraveller";
import { GetNextValidStep } from "../../components/Steps";

export const RoomAssignment = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;
  const { updateTraveller } = UseUpdateTraveller();
  const { updateBooking } = UseUpdateBookingHook();
  const { booking, loading } = UseGetBooking(bookingId);
  const [openRoom, setOpenRoom] = useState<number>(0);
  const nextStep = GetNextValidStep(booking, 4);
  if (loading || !booking) {
    return <Loader />;
  }

  const updateMainBooker = (travellerId: string) => {
    updateBooking({ id: bookingId, values: { mainBooker: travellerId } });
  };

  let availableTravellers = booking.travellers.map((x) => ({
    id: x.id,
    firstName: x.firstName,
    lastName: x.lastName,
  }));

  //Filter traveller options
  let occupiedIds: string[] = [];
  booking?.travellers?.forEach((t) => {
    if (t.room) occupiedIds.push(t.id);
  });

  availableTravellers = availableTravellers.filter(
    (x) => !occupiedIds.includes(x.id)
  );

  let roomsFilled = true;
  booking?.rooms?.forEach((r) => {
    const travellersForRoom = booking?.travellers?.filter(
      (t) => t.room === r.id
    );
    if (!(travellersForRoom?.length === r.size + 1)) {
      roomsFilled = false;
    }
  });
  let nextStepReady = roomsFilled && booking?.mainBooker;

  return (
    <Page>
      <Steps current={3} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <div>
          <Card>
            <Header>Selecteer de hoofdboeker</Header>
            <TravellerObject>
              <TravellerContent>
                <p>
                  Hier kies je wie de hoofdboeker gaat zijn van jullie
                  reservatie. Deze persoon is het eerste aanspreekpunt binnen de
                  groep, maar iedereen uit de boeking zal steeds alle informatie
                  ontvangen en dus niet enkel de hoofdboeker.
                </p>
                <StyledDropdown
                  value={booking?.mainBooker?.id}
                  handleChange={(id) => updateMainBooker(id)}
                  options={booking.travellers?.map((x) => ({
                    value: x.id,
                    label: `${x.firstName} ${x.lastName}`,
                  }))}
                />
              </TravellerContent>
            </TravellerObject>
          </Card>
          <Card>
            <Header>Verdeel de reizigers over de kamers</Header>
            {booking?.rooms.map((room, i) => (
              <Room
                size={room.size}
                travellers={booking?.travellers.filter(
                  (t) => t.room === room.id
                )}
                isOpen={openRoom === i}
                id={room.id}
                setOpen={() => setOpenRoom(i)}
                addTraveller={(travellerId, roomId) =>
                  updateTraveller({
                    id: travellerId,
                    values: {
                      room: roomId,
                    },
                    bookingId,
                    initTravellers: booking?.travellers,
                  })
                }
                removeTraveller={(id) =>
                  updateTraveller({
                    id,
                    values: {
                      room: null,
                    },
                    bookingId,
                    initTravellers: booking?.travellers,
                  })
                }
                availableTravellers={availableTravellers}
                key={room.id}
              />
            ))}
          </Card>
          <Buttons>
            <Link
              to={`/step3?id=${bookingId}&brand=${brand.code}`}
              onClick={() => {
                const updatedBookingTravellers = booking?.travellers?.map(
                  (traveller) => {
                    return { ...traveller, room: null };
                  }
                );
                booking?.travellers?.forEach((t) => {
                  updateTraveller({
                    id: t.id,
                    values: {
                      room: null,
                    },
                    bookingId,
                    initTravellers: updatedBookingTravellers,
                  });
                });
              }}
            >
              <Button color="#655C7E">Vorige stap</Button>
            </Link>
            {nextStepReady ? (
              <a href={`/step${nextStep}?id=${bookingId}&brand=${brand.code}`}>
                <Button color={brand.color}>Volgende stap</Button>
              </a>
            ) : (
              <Button color="#655C7E">Volgende stap</Button>
            )}
          </Buttons>
        </div>
      </Layout>
    </Page>
  );
};
