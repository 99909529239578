export const calculateExcursions = (travellers: TravellerType[]) => {
  const result = {
    total: 0,
    price: 0,
  };
  travellers?.forEach((t) => {
    t?.excursions?.forEach((excursion) => {
      result.total++;
      result.price = result.price + excursion.price;
    });
  });
  return result;
};
