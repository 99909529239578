import styled from "styled-components";

export const StyledInput = styled.div`
  input {
    box-sizing: border-box;
    text-indent: 20px;
    width: 100%;
    padding: 16px 9px;
    border: 1.5px solid #655c7e;
    border-radius: 10px;
    background-color: #ffffff;
  }
`;
