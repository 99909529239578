import React from "react";
import StartImage from "../../images/oops.png";
import { ContextType, useAppState } from "../../store";
import {
  Title,
  StyledImage,
  Container,
  Box,
  StyledButton,
  Text,
} from "./Error.styled";

export const Error = () => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  return (
    <Container>
      <Box>
        <StyledImage src={StartImage} alt="start" />
        <Title>Helaas, hier ben je verkeerd.</Title>
        <Text>Klik hieronder om naar de website terug te keren.</Text>
        <a href={brand?.url} style={{ width: "60%" }}>
          <StyledButton color={brand?.color}>
            Terug naar de website
          </StyledButton>
        </a>
      </Box>
    </Container>
  );
};
