import styled from "styled-components";
import { media } from "../../helpers/mediaQueries";

export const Container = styled.div``;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const Title = styled.div`
  color: #231747;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.23px;
  line-height: 19px;
  width: 30%;
  ${media.tablet`
  margin-bottom: 10px;
  text-align: left;
`}
`;

export const Input = styled.input<{ error: boolean }>`
  display: block;
  box-sizing: border-box;
  color: #231747;
  padding: 16px 8px;
  border: 1.5px solid ${({ error }) => (error ? "red" : "#655c7e")};
  border-radius: 10px;
  text-indent: 10px;
  width: 100%;
`;

export const InputContainer = styled.div`
  flex: 1;
  ${media.tablet`
  width: 100%;
`}
`;

export const Error = styled.div<{ visible: boolean }>`
  position: absolute;
  color: red;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;

export const ParentField = styled.div`
  margin-bottom: 40px;
  border-top: 1px solid rgb(138 144 144 / 34%);
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(138 144 144 / 34%);
`;
