import dayjs from "dayjs";
const amountPerDay = 2.5;

export const calculateTravelInsurance = (
  period: PeriodType,
  transport: "bus" | "plane" | "self"
) => {
  const startDate = dayjs(period.start * 1000);
  const endDate = dayjs(period.end * 1000);
  const duration = endDate.diff(startDate, "day") + 1;
  if (transport === "bus") {
    return (duration + 2) * amountPerDay;
  }

  return duration * amountPerDay;
};
