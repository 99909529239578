import React from "react";
import {
  Field,
  Title,
  Input,
  InputContainer,
  Error,
} from "./TravellerForm.styled";
import { RadioButtons } from "../RadioButtons";
import { DateInput } from "../DateInput";
import { RegistryNumberInput } from "../RegistryNumberInput";
import { Dropdown } from "../Dropdown";

type FormFieldProps = {
  title: string;
  name: string;
  type?:
    | "text"
    | "radio"
    | "registryNumber"
    | "registryNumberNL"
    | "date"
    | "select";
  handleBlur?: () => void;
  handleChange: (arg: any) => void;
  value: any;
  options?: any[];
  error: any;
  customPlaceholder?: string;
};

export const FormField: React.FC<FormFieldProps> = ({
  title,
  name,
  type = "text",
  handleBlur = () => null,
  handleChange,
  value,
  options,
  error,
  customPlaceholder,
}) => {
  if (type === "radio") {
    return (
      <Field>
        <Title>{title}</Title>
        <InputContainer>
          <RadioButtons
            handleChange={handleChange}
            value={value}
            options={["male", "female", "other"]}
          />
          <Error visible={error}>{error}</Error>
        </InputContainer>
      </Field>
    );
  }
  if (type === "select") {
    return (
      <Field>
        <Title>{title}</Title>
        <InputContainer>
          <Dropdown
            handleChange={handleChange}
            name={name}
            value={value}
            options={options as any[]}
          />
          <Error visible={error}>{error}</Error>
        </InputContainer>
      </Field>
    );
  }
  if (type === "date") {
    return (
      <Field>
        <Title>{title}</Title>
        <InputContainer>
          <DateInput handleChange={handleChange} name={name} value={value} />
          <Error visible={error}>{error}</Error>
        </InputContainer>
      </Field>
    );
  }
  if (type === "registryNumber") {
    return (
      <Field>
        <Title>{title}</Title>
        <InputContainer>
          <RegistryNumberInput
            isNL={false}
            handleChange={handleChange}
            name={name}
            value={value}
          />
          <Error visible={error}>{error}</Error>
        </InputContainer>
      </Field>
    );
  }
  if (type === "registryNumberNL") {
    return (
      <Field>
        <Title>{title}</Title>
        <InputContainer>
          <RegistryNumberInput
            isNL
            handleChange={handleChange}
            name={name}
            value={value}
          />
          <Error visible={error}>{error}</Error>
        </InputContainer>
      </Field>
    );
  }
  return (
    <Field>
      <Title>{title}</Title>
      <InputContainer>
        <Input
          error={error}
          name={name}
          placeholder={customPlaceholder || title}
          type={type}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
        />
        <Error visible={error}>{error}</Error>
      </InputContainer>
    </Field>
  );
};

export default FormField;
