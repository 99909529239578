import React, { FC } from "react";
import {
  MarkerIcon,
  RightArrowIcon,
  UserAccountIcon,
  BusIcon,
  CutleryIcon,
  MoneyIcon,
  InfoIcon,
} from "@travelquest/web-components";
import {
  StyledCard,
  Field,
  Title,
  Value,
  CardLine,
  MultipleField,
  StyledIcon,
  TotalBlock,
  TotalText,
  TotalPrice,
  StyledCardMobile,
  HotelInfo,
  HotelImage,
  HotelName,
  StyledStars,
  HotelLocation,
  TimezoneWarning,
} from "./BookingInformation.styled";
import {
  getImageFromServer,
  calculateTravelInsurance,
  dayjsWithTimezone,
} from "../../helpers";
import { ContextType, useAppState } from "../../store";
import { calculateAddons } from "./calculateAddons";
import { calculateExcursions } from "./calculateExcursions";
import { calculateInsurances } from "./calculateInsurances";
import { calculateAnnulationInsurance } from "./calculateAnnulationInsurance";
import { calculateTotalPrice } from "./calculateTotalPrice";
import { calculateRooms } from "./calculateRooms";
import { mealTypes, transportOptions } from "./constants";
import { useTranslation } from "react-i18next";

export type Props = {
  booking: BookingType;
  loading: boolean;
};

export const BookingInformation: FC<Props> = ({ booking, loading }) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  const offset = new Date().getTimezoneOffset();
  const showTimeZoneIndicator = offset !== -120;
  const { targetAudienceEnabled } = brand.features;
  const { t } = useTranslation(["general", "addons"]);

  if (loading) {
    return null;
  }

  let totalTravellers = 0;
  booking?.rooms?.forEach((r: RoomType) => {
    totalTravellers = totalTravellers + (r.size + 1);
  });

  const accommodation = booking?.brandAccommodation?.accommodation;

  let startDate: string = "";
  const _startDate = dayjsWithTimezone(booking.period.start * 1000);

  if (booking.transport === "bus") {
    startDate = _startDate.subtract(1, "day").format("DD/MM/YYYY");
  } else {
    startDate = _startDate.format("DD/MM/YYYY");
  }

  let endDate: string = "";
  const _endDate = dayjsWithTimezone(booking.period.end * 1000);

  if (booking.transport === "bus") {
    endDate = _endDate.add(1, "day").format("DD/MM/YYYY");
  } else {
    endDate = _endDate.format("DD/MM/YYYY");
  }

  const prices =
    booking?.period?.prices?.[booking?.mealType]?.[booking?.transport];

  const insurances = calculateInsurances(
    booking.travellers || [],
    booking?.rooms
  );

  const rooms = calculateRooms(booking?.rooms);

  const travelInsurancePrice = calculateTravelInsurance(
    booking.period,
    booking.transport
  );

  const total = calculateTotalPrice(
    booking.rooms,
    prices,
    insurances.travelInsurances,
    travelInsurancePrice
  );

  const annulationInsurance = calculateAnnulationInsurance(
    prices[booking?.rooms[0].size].discounted
  );

  const excursionTotals = calculateExcursions(booking?.travellers);
  const addonTotals = calculateAddons(
    booking?.travellers,
    booking?.brandAccommodation?.brandDestination?.addons,
    booking?.period?.start
  );

  return (
    <div>
      <HotelInfo>
        <HotelImage
          src={getImageFromServer(accommodation.coverImage?.url, "1:1", 400)}
          alt={accommodation.coverImage?.alt}
        />
        <HotelName>
          {accommodation.name?.nlbe}
          <span>
            <StyledStars count={accommodation?.rating} />
          </span>
          <HotelLocation color={brand.color}>
            <MarkerIcon /> {accommodation.location}
          </HotelLocation>
          <MultipleField $isMobile={true}>
            <Value>{startDate}</Value>
            <StyledIcon>
              <RightArrowIcon />
            </StyledIcon>
            <Value>{endDate}</Value>
          </MultipleField>
          {!showTimeZoneIndicator && (
            <TimezoneWarning color={brand.color}>
              <InfoIcon />
              Opgelet, alle tijden zijn in tijdszone GMT+2
            </TimezoneWarning>
          )}
        </HotelName>
      </HotelInfo>
      <StyledCardMobile>
        <Field>
          <StyledIcon>
            <UserAccountIcon />
          </StyledIcon>
          <CardLine />
          <Value>{totalTravellers}</Value>
        </Field>
        <Field>
          <StyledIcon>
            <BusIcon />
          </StyledIcon>
          <CardLine />
          <Value>{transportOptions[booking.transport]}</Value>
        </Field>
        <Field>
          <StyledIcon>
            <CutleryIcon />
          </StyledIcon>
          <CardLine />
          <Value>{mealTypes[booking.mealType]}</Value>
        </Field>
        <Field>
          <StyledIcon>
            <MoneyIcon />
          </StyledIcon>
          <CardLine />
          <Value>
            €{" "}
            {total +
              insurances.annulationInsuranceTotal +
              excursionTotals.price +
              addonTotals.price}
          </Value>
        </Field>
      </StyledCardMobile>
      <StyledCard>
        <Field>
          <Title>Aantal reizigers</Title>
          <Value>{totalTravellers} personen</Value>
        </Field>
        <CardLine />
        <MultipleField>
          <div>
            <Title>Van</Title>
            <Value>{startDate}</Value>
          </div>
          <StyledIcon>
            <RightArrowIcon />
          </StyledIcon>
          <div>
            <Title>Tot</Title>
            <Value>{endDate}</Value>
          </div>
        </MultipleField>
        <CardLine />
        <Field>
          <Title>{t("general:transport")}</Title>
          <Value>{transportOptions[booking.transport]}</Value>
        </Field>
        <CardLine />
        <Field>
          <Title>Maaltijdsformule</Title>
          <Value>{mealTypes[booking.mealType]}</Value>
        </Field>
        <CardLine />
        {targetAudienceEnabled && (
          <>
            <Field>
              <Title>Doelgroep</Title>
              <Value>{booking.targetAudience?.name?.nlbe}</Value>
            </Field>
            <CardLine />
          </>
        )}

        {Object.keys(rooms).map((key) => (
          <MultipleField
            key={`room-${key}`}
            style={{ justifyContent: "space-between" }}
          >
            <Title>
              {rooms[key]}x {Number(key)}-persoonskamer
            </Title>
            <Value>
              €{" "}
              {Math.ceil(rooms[key] * prices[Number(key) - 1].discounted) *
                Number(key)}
            </Value>
          </MultipleField>
        ))}
        {insurances.travelInsurances ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {insurances.travelInsurances}x {t("addons:travelinsurance")}
            </Title>
            <Value>€ {travelInsurancePrice}</Value>
          </MultipleField>
        ) : null}
        {insurances.annulationInsurances ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {insurances.annulationInsurances}x{" "}
              <span>{t("addons:annulationInsurance")}</span>
            </Title>
            <Value>€ {insurances.annulationInsuranceTotal}</Value>
          </MultipleField>
        ) : null}
        {excursionTotals.total !== 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {excursionTotals.total}x excursies, events en partydeals
            </Title>
            <Value>€ {excursionTotals.price}</Value>
          </MultipleField>
        ) : null}
        {addonTotals.total !== 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>{addonTotals.total}x extra's.</Title>
            <Value>€ {addonTotals.price}</Value>
          </MultipleField>
        ) : null}
        <TotalBlock color={brand.color}>
          <MultipleField
            style={{
              padding: 0,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <TotalText>Totaalprijs</TotalText>
              <TotalText>Voor {totalTravellers} reizigers</TotalText>
            </div>
            <TotalPrice>
              €{" "}
              {total +
                insurances.annulationInsurances * annulationInsurance +
                excursionTotals.price +
                addonTotals.price}
            </TotalPrice>
          </MultipleField>
        </TotalBlock>
      </StyledCard>
    </div>
  );
};
