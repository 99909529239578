import styled from "styled-components";
import { media } from "../../helpers/mediaQueries";
import { Dropdown } from "../../components";

export const Layout = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 3fr 5fr;
  ${media.tablet`
  grid-template-columns: 1fr;
`}
`;

export const Header = styled.div`
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding: 20px;
  background-color: #231747;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const StyledDropdown = styled(Dropdown)`
  min-width: 100px;
  margin-right: 20px;
`;

export const TravellerContent = styled.div`
  padding: 16px 20px;
`;

export const TravellerObject = styled.div`
  width: 100%;
`;
