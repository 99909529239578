import styled from "styled-components";
import { CheckmarkIcon, InfoIcon } from "@travelquest/web-components";

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  justify-content: space-between;
  margin-bottom: 21px;
`;

export const CheckBoxName = styled.span<{ disabled: boolean }>`
  color: #231747;
  font-size: 14px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: Capitalize;
`;

export const Checked = styled(CheckmarkIcon)`
  border-radius: 3.33px;
  background-color: #2f1f5d;
  color: white;
  padding: 3px;
  font-size: 13px;
  margin-right: 8px;
`;
export const Unchecked = styled.div`
  margin-right: 8px;
  height: 16px;
  width: 16px;
  border: 1px solid #dedce3;
  border-radius: 3.33px;
  background-color: #ffffff;
`;

export const Price = styled.div`
  color: #231747;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
  white-space: nowrap;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  font-size: 18px;
  color: #5e3dbc;
`;
