import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

import { Error } from "./pages/Error";
import { Introduction } from "./pages/Introduction";
import { ChooseRooms } from "./pages/ChooseRooms";
import { TravellerInformation } from "./pages/TravellerInformation";
import { RoomAssignment } from "./pages/RoomAssignment";
import { BookExtras } from "./pages/BookExtras";
import { Confirmation } from "./pages/Confirmation";
import { Finish } from "./pages/Finish";
import { useAppState, setInitialData, ContextType } from "./store";
import { GET_BOOKING_BY_ID } from "./queries/booking";
import { Loader } from "./components";
import { GET_ESSENTIALS } from "./queries/essentials";
import { BookAddons } from "./pages/BookAddons";
import { TravelOptions } from "./pages/TravelOptions";

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}
function App() {
  const { appStateDispatch, appState } = useAppState() as ContextType;
  let query = useUrlQuery();
  const { i18n } = useTranslation();

  const id = query.get("id");
  const { data, loading } = useQuery(GET_BOOKING_BY_ID, {
    skip: !id,
    variables: {
      id,
    },
  });

  const booking = data?.booking;
  const { data: essentialData } = useQuery(GET_ESSENTIALS, {
    skip: !booking,
    variables: {
      code: booking?.brand?.code,
    },
  });
  const brand = essentialData?.brand;
  const { trackingCode } = essentialData?.projectSettings || {};
  const favicon = essentialData?.projectSettings?.identity?.favicon?.url;
  const logo = essentialData?.projectSettings?.identity?.logo?.url;
  const logoWithText =
    essentialData?.projectSettings?.identity?.logoWithText?.url;
  useEffect(() => {
    if (id && brand) {
      appStateDispatch(
        setInitialData({
          brand,
          bookingId: id as string,
          favicon,
          logo,
          logoWithText,
          trackingCode,
        })
      );
      i18n.changeLanguage(brand.languages?.[0]?.code?.toLowerCase() || "nlbe");
    }
  }, [
    brand,
    id,
    appStateDispatch,
    favicon,
    i18n,
    trackingCode,
    logo,
    logoWithText,
  ]);
  useEffect(() => {
    if (trackingCode) {
      TagManager.initialize({ gtmId: trackingCode });
    }
  }, [trackingCode]);
  if (!id || (!loading && !booking) || booking?.status === "booked") {
    console.log("error");
    return <Error />;
  }
  // Without booking info or project info, we can't continue
  if (!booking || !appState.brand) {
    return <Loader />;
  }
  return (
    <Routes>
      <Route path="/" element={<Introduction />} />
      <Route path="/step2" element={<ChooseRooms />} />
      <Route path="/step3" element={<TravellerInformation />} />
      <Route path="/step4" element={<RoomAssignment />} />
      <Route path="/step5" element={<TravelOptions />} />
      <Route path="/step6" element={<BookExtras />} />
      <Route path="/step7" element={<BookAddons />} />
      <Route path="/step8" element={<Confirmation />} />
      <Route path="/step9" element={<Finish />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
}

export default App;
