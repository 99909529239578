import React, { useState, useEffect } from "react";
import { Button } from "@travelquest/web-components";
import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
} from "../../components";

import {
  Layout,
  Header,
  Buttons,
  StyledDropdown,
  LabelAndDropdownWrapper,
  StyledLabel,
  DropdownWrapper,
  ButtonWrapper,
} from "./BookExtras.styled";
import { Traveller } from "./Traveller";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { UseUpdateTraveller } from "../../hooks/UseUpdateTraveller";

export const addonCategoriesMapping: Record<string, string> = {
  basicNeeds: "Basis benodigdheden",
  skiMaterial: "Ski materiaal",
  snowboardMaterial: "Snowboard materiaal",
  skiPass: "Ski pas",
  skiLessons: "Ski lessen",
  insurance: "Verzekering",
};

export const BookAddons = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;
  const brandCode = brand?.code;
  const { booking, loading } = UseGetBooking(bookingId);
  const { updateTraveller } = UseUpdateTraveller();
  const [openTraveller, setOpenTraveller] = useState<number | null>(0);
  const [selectedCategory, setSelectedCategory] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [selectedTag, setSelectedTag] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [travellerAddons, setTravellerAddons] = useState<any>();
  const [addonsLoading, setAddonsLoading] = useState(true);

  useEffect(() => {
    if (booking?.travellers) {
      const initialAddons = booking?.travellers.reduce((acc, traveller) => {
        return { ...acc, [traveller.id]: traveller.addons };
      }, {});
      setTravellerAddons(initialAddons);
      setAddonsLoading(false);
    }
  }, [booking]);

  const updateTravellerAddons = (travelerId: string, addons: string[]) => {
    setTravellerAddons((prevState: any) => ({
      ...prevState,
      [travelerId]: addons,
    }));
  };

  const update = (
    id: string,
    values: Record<string, any>,
    bookingId: string,
    travellers: any
  ) => {
    updateTraveller({ id, values, bookingId, initTravellers: travellers });
  };

  if (loading || !booking || addonsLoading) {
    return <Loader />;
  }

  const roomsById: Record<string, RoomType> = {};
  booking.rooms.forEach((r) => {
    roomsById[r.id] = r;
  });

  const addons = booking?.brandAccommodation?.brandDestination?.addons;

  const categoryOptions: any = addons?.reduce((acc: any, addon) => {
    const addonCategories =
      addon?.categories?.map((category) => ({
        value: category,
        label: addonCategoriesMapping?.[category],
      })) || [];

    addonCategories?.forEach((category) => {
      if (!acc.some((option: any) => option.value === category.value)) {
        acc.push(category);
      }
    });
    return acc;
  }, []);

  const addonsFilteredOnTags = addons?.filter((addon) => addon?.tags !== null);

  const tagsOptions: any = addonsFilteredOnTags?.reduce((acc: any, addon) => {
    if (
      addon?.categories?.includes(selectedCategory || categoryOptions[0]?.value)
    ) {
      const addonTags =
        addon?.tags?.map((tag) => ({ value: tag, label: tag })) || [];
      addonTags?.forEach((tag) => {
        if (!acc.some((option: any) => option.value === tag.value)) {
          acc.push(tag);
        }
      });
    }
    return acc;
  }, []);

  const finalFilterAddons = addons?.filter((addon) => {
    const tagToUse = selectedTag || tagsOptions?.[0]?.value;

    const categoryToUse = selectedCategory || categoryOptions?.[0]?.value;

    if (!tagToUse && !categoryToUse) {
      return true;
    }

    return (
      addon?.tags?.includes(tagToUse) &&
      addon?.categories?.includes(categoryToUse)
    );
  });

  if (brandCode === "mbbe") {
    return (
      <Page>
        <Steps current={6} />
        <Layout>
          <BookingInformation booking={booking} loading={loading} />
          <div>
            <Card>
              <Header>Extra's</Header>
              <p>
                Kies per persoon welke eventuele extra's je wenst toe te voegen
                aan je reservatie.
              </p>
              <DropdownWrapper>
                <ButtonWrapper>
                  {categoryOptions?.map((category: any, i: number) => (
                    <Button
                      key={category?.label}
                      color={brand.color}
                      onClick={() => {
                        setSelectedCategory(categoryOptions?.[i]?.value);
                        setSelectedTag(null);
                      }}
                    >
                      {category?.label}
                    </Button>
                  ))}
                </ButtonWrapper>
              </DropdownWrapper>
              <LabelAndDropdownWrapper>
                <StyledLabel>Subcategorie</StyledLabel>
                <StyledDropdown
                  handleChange={(value) => setSelectedTag(value)}
                  value={selectedTag || tagsOptions?.[0]?.value}
                  options={tagsOptions}
                />
              </LabelAndDropdownWrapper>
              {booking?.travellers.map((t, i) => (
                <Traveller
                  index={i}
                  isOpen={openTraveller === i}
                  setOpen={setOpenTraveller}
                  travellerData={t}
                  key={t.id}
                  finalFilterAddons={finalFilterAddons}
                  update={(values) =>
                    update(t.id, values, bookingId, booking?.travellers)
                  }
                  selectedAddons={travellerAddons || {}}
                  updateTravellerAddons={updateTravellerAddons}
                  period={booking?.period}
                />
              ))}
            </Card>
            <Buttons>
              <a href={`/step6?id=${bookingId}&brand=${brand.code}`}>
                <Button color="#655C7E">Vorige stap</Button>
              </a>
              <a href={`/step8?id=${bookingId}&brand=${brand.code}`}>
                <Button color={brand.color}>Volgende stap</Button>{" "}
              </a>
            </Buttons>
          </div>
        </Layout>
      </Page>
    );
  } else {
    return (
      <Page>
        <Steps current={6} />
        <Layout>
          <BookingInformation booking={booking} loading={loading} />
          <div>
            <Card>
              <Header>Extra's</Header>
              <p>
                Kies per persoon welke eventuele extra's je wenst toe te voegen
                aan je reservatie.
              </p>
              <DropdownWrapper>
                <LabelAndDropdownWrapper>
                  <StyledLabel>Categorie</StyledLabel>
                  <StyledDropdown
                    handleChange={(value) => {
                      setSelectedCategory(value);
                      setSelectedTag(null);
                    }}
                    value={selectedCategory || categoryOptions?.[0]?.value}
                    options={categoryOptions}
                  />
                </LabelAndDropdownWrapper>
                <LabelAndDropdownWrapper>
                  <StyledLabel>Subcategorie</StyledLabel>
                  <StyledDropdown
                    handleChange={(value) => setSelectedTag(value)}
                    value={selectedTag || tagsOptions?.[0]?.value}
                    options={tagsOptions}
                  />
                </LabelAndDropdownWrapper>
              </DropdownWrapper>
              {booking?.travellers.map((t, i) => (
                <Traveller
                  index={i}
                  isOpen={openTraveller === i}
                  setOpen={setOpenTraveller}
                  travellerData={t}
                  key={t.id}
                  finalFilterAddons={finalFilterAddons}
                  update={(values) =>
                    update(t.id, values, bookingId, booking?.travellers)
                  }
                  selectedAddons={travellerAddons || {}}
                  updateTravellerAddons={updateTravellerAddons}
                  period={booking?.period}
                />
              ))}
            </Card>
            <Buttons>
              <a href={`/step6?id=${bookingId}&brand=${brand.code}`}>
                <Button color="#655C7E">Vorige stap</Button>
              </a>
              <a href={`/step8?id=${bookingId}&brand=${brand.code}`}>
                <Button color={brand.color}>Volgende stap</Button>{" "}
              </a>
            </Buttons>
          </div>
        </Layout>
      </Page>
    );
  }
};
