import { gql } from "@apollo/client";

export const GET_ESSENTIALS = gql`
  query essentials($code: String!) {
    brand(code: $code) {
      name
      id
      logo
      code
      color
      url
      languages {
        id
        name
        code
        flag
      }
      country
      features {
        targetAudienceEnabled
      }
    }
    projectSettings(brand: $code) {
      id
      disableBooking
      trackingCode
      phoneNumber
      pageLinks {
        insurance {
          id
        }
        touristTax {
          id
        }
        identification {
          id
        }
        mealTypes {
          id
        }
      }
      identity {
        logo {
          url
        }
        logoWithText {
          url
        }
        favicon {
          url
        }
      }
    }
  }
`;
