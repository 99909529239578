export const calculateTotalPrice = (
  rooms: RoomType[],
  prices: PriceType[],
  travelInsurances = 0,
  travelInsurancePrice = 0
) => {
  let total = 0;
  rooms.forEach((x) => {
    const price = prices[x.size];
    total = total + Math.ceil(Number(price.discounted)) * (x.size + 1);
  });
  total = total + travelInsurances * travelInsurancePrice;
  return Math.ceil(total);
};
