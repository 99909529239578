import styled from "styled-components";
import { media } from "../../helpers/mediaQueries";

export const Marker = styled.div<{ $active: boolean }>`
  height: 24px;
  width: 24px;
  border: ${({ $active }) =>
    $active ? "2px solid #231747" : "1.5px solid #dedce3"};
  border-radius: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MarkerActiveDot = styled.div`
  height: 14px;
  width: 14px;
  background-color: #231747;
  border-radius: 14px;
`;

export const Header = styled.div`
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding: 20px;
  background-color: #231747;
`;

export const RoomContent = styled.div`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  span {
    color: #093949;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.26px;
    line-height: 22px;
    text-align: right;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  justify-content: space-between;
  ${media.tablet`
    justify-content: center;
  `}
`;

export const Layout = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 3fr 5fr;
  ${media.tablet`
  grid-template-columns: 1fr;
  `};
`;
