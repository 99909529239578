import React, { ReactNode, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import {
  CheckBoxContainer,
  CheckBoxName,
  Checked,
  Unchecked,
  Price,
  StyledInfoIcon,
} from "./Checkbox.styled";

type Props = {
  id: string;
  price: number;
  callback: (checked: boolean) => void;
  initialValue?: boolean;
  children: ReactNode;
  value?: boolean;
  disabled?: boolean;
  showTooltip?: boolean;
};

export const Checkbox: React.FC<Props> = ({
  initialValue,
  id,
  callback,
  value,
  children,
  price,
  disabled,
  showTooltip,
}) => {
  const [checked, setChecked] = useState(initialValue || false);

  const toggle = () => {
    if (!disabled) {
      callback(!checked);
      setChecked(!checked);
    }
  };

  useEffect(() => {
    if (value) {
      setChecked(value);
    }
  }, [value]);
  return (
    <CheckBoxContainer
      id={id}
      data-tooltip-content="Deze zijn enkel ter plaatse te boeken"
      onClick={toggle}
      data-tip="notBookable"
    >
      {showTooltip && <Tooltip anchorId={id} />}
      <CheckBoxName disabled={disabled as boolean}>
        {checked ? <Checked /> : <Unchecked />}
        {children}
      </CheckBoxName>
      {!disabled ? (
        <Price>€ {price}</Price>
      ) : (
        <span data-tip data-for="tip">
          <StyledInfoIcon />
        </span>
      )}
    </CheckBoxContainer>
  );
};
