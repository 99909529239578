import React from "react";
import { Button } from "@travelquest/web-components";
import { Link } from "react-router-dom";

import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
} from "../../components";

import {
  Layout,
  Header,
  Buttons,
  StyledDropdown,
  AirportSelection,
  Description,
} from "./TravelOptions.styled";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { UseUpdateBookingHook } from "../../hooks/UseUpdateBookingHook";

export const TravelOptions = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;
  const { booking, loading } = UseGetBooking(bookingId);
  const { updateBooking } = UseUpdateBookingHook();

  if (loading || !booking) {
    return <Loader />;
  }

  const airports: AirportType[] =
    booking?.brandAccommodation?.brandDestination?.airports;

  const language = brand?.languages?.[0]?.code || "nlbe";
  const sanitizedLanguage = language?.trim()?.toLowerCase();

  return (
    <Page>
      <Steps current={4} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <div>
          <Card>
            <Header>Kies uw luchthaven voor vertrek</Header>
            <Description>
              <p>
                Summer Bash probeert zoveel mogelijk rekening te houden met jouw
                wensen en voorkeuren. Daarom kan je hier alvast jouw
                voorkeursluchthaven doorgeven.
              </p>
              <p>
                Met je opgegeven voorkeursluchthaven proberen we zoveel mogelijk
                rekening te houden. Op basis van beschikbaarheid en de beste
                vluchturen kiest ons team zorgvuldig naar de beste luchthaven
                voor je heen- en terugreis. ✈️
              </p>
              <p>
                Je vluchtgegevens zullen na je reservatie zo snel mogelijk
                zichtbaar zijn in je online account. Hier kan je dan alle
                informatie voor jouw vliegreis (luchthaven,
                vliegtuigmaatschappij, vluchturen, vluchtnummer…) raadplegen. Al
                deze gegevens komen online te staan zodra we jouw reis hebben
                verwerkt.😎
              </p>
            </Description>
            <AirportSelection>
              <StyledDropdown
                handleChange={(value: string) =>
                  updateBooking({ id: bookingId, values: { airport: value } })
                }
                value={booking?.airport?.id || ""}
                options={airports?.map((airport) => ({
                  value: airport.id,
                  label:
                    airport.name?.[
                      sanitizedLanguage as keyof LanguageObjectType
                    ],
                }))}
              />
            </AirportSelection>
          </Card>
          <Buttons>
            <Link to={`/step4?id=${bookingId}&brand=${brand.code}`}>
              <Button color="#655C7E">Vorige stap</Button>
            </Link>
            {booking?.airport ? (
              <a href={`/step6?id=${bookingId}&brand=${brand.code}`}>
                <Button color={brand.color}>Volgende stap</Button>{" "}
              </a>
            ) : (
              <Button color="#655C7E">Volgende stap</Button>
            )}
          </Buttons>
        </div>
      </Layout>
    </Page>
  );
};
