import dayjs from "dayjs";
import  isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export const unixToDate = (timestamp: number) => {
  return dayjs(timestamp * 1000);
};

export const unixToFormattedDate = (timestamp: number) => {
  return unixToDate(timestamp).format("DD/MM/YYYY");
};
