import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

const _dayjs = dayjs;

_dayjs.extend(customParseFormat);
_dayjs.extend(utc);
_dayjs.extend(timezone);
_dayjs.tz.setDefault("Europe/Brussels");

export const dayjsWithTimezone = _dayjs;
