import { ApolloError, useQuery } from "@apollo/client";
import { GET_BOOKING_BY_ID } from "../queries/booking";

type Result = {
  loading: boolean;
  error?: ApolloError;
  booking: BookingType;
  refetch: () => void;
};

export const UseGetBooking = (id: string): Result => {
  const { loading, data, error, refetch } = useQuery(GET_BOOKING_BY_ID, {
    skip: !id,
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  });
  const booking = data?.booking;
  return { loading, error, booking, refetch };
};
