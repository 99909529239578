import { css } from "styled-components";

const breakpoints: Record<string, string> = {
  mobile: "320px",
  mobileL: "480px",
  tablet: "768px",
  laptop: "990px",
  laptopL: "1240px",
  desktop: "1440px",
  desktopL: "1920px",
  desktopXL: "2048px",
};

// Iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce(
  (acc: Record<string, any>, label: string) => {
    acc[label] = (...args: [any]) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(...args)}
      }
    `;

    return acc;
  },
  {}
);
