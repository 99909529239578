import { gql, ApolloError, useMutation } from "@apollo/client";
import { UPDATE_BOOKING } from "../queries/booking";

type Props = {
  id: string;
  values: Record<string, any>;
};

type Result = {
  loading: boolean;
  error?: ApolloError;
  updateBooking: Function;
};

export const UseUpdateBookingHook = (): Result => {
  const [updateBooking, { loading, error }] = useMutation(UPDATE_BOOKING);

  const update = ({ id, values }: Props) => {
    const { targetAudience, ..._values } = values;
    updateBooking({
      variables: {
        input: {
          id: id,
          targetAudience: targetAudience?.id,
          ..._values,
        },
      },
      update(cache, { data: { updateBooking } }) {
        if (updateBooking.success) {
          const data = cache.readFragment({
            id: `Booking:${id}`,
            fragment: gql`
              fragment MyBooking on Booking {
                id
                referal
                mainBooker
                targetAudience
                airport
              }
            `,
          });

          cache.writeFragment({
            id: `Booking:${id}`,
            fragment: gql`
              fragment MyBooking on Booking {
                id
                referal
                mainBooker
                targetAudience
                airport
              }
            `,
            data: {
              __typename: "Booking",
              ...(data as BookingType),
              ...values,
            },
          });
        } else {
          console.error("failed to update booking");
        }
      },
    });
  };

  return { loading, error, updateBooking: update };
};
