import React, { ReactNode } from "react";
import { Container } from "./Card.styled";

type CardProps = {
  children: ReactNode;
  className?: string;
};

export const Card: React.FC<CardProps> = ({ children, className }) => {
  return <Container className={className}>{children}</Container>;
};
