import React from "react";
import { Rings } from "react-loader-spinner";
import { useAppState, ContextType } from "../../store";
import { Container, Box } from "./Loader.styled";

type LoaderProps = {
  text?: string;
};

export const Loader = ({ text }: LoaderProps) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  return (
    <Container>
      <Box>
        <Rings color={brand?.color || "#231747"} height={100} width={100} />
        <h2>{text || "We laden je reis, even geduld..."}</h2>
      </Box>
    </Container>
  );
};
