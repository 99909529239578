import React, { useState } from "react";
import { gql } from "@apollo/client";
import {
  RoomCount,
  RoomLabel,
  RoomPrice,
  RoomContent,
  RoomMultiplierIcon,
  AddRoomContainer,
  Header,
  StyledButton,
  StyledDropdown,
} from "./RoomCard.styled";
import { useMutation } from "@apollo/client";
import { ADD_ROOM, REMOVE_ROOM } from "../../../queries/rooms";
import { Card, CardLine } from "../../../components";
import { Button, TrashIcon } from "@travelquest/web-components";
import { ContextType, useAppState } from "../../../store";
import { INIT_TRAVELLERS, REMOVE_TRAVELLER } from "../../../queries/traveller";

type RoomProps = {
  id: string;
  size: number;
  price: PriceType;
  removeRoom: (id: string) => void;
  rooms: any;
};

const Room = ({ id, size, price, removeRoom, rooms }: RoomProps) => {
  return (
    <>
      <RoomContent>
        <RoomLabel>
          <RoomCount>1</RoomCount>
          <RoomMultiplierIcon>x</RoomMultiplierIcon>
          {size + 1} persoons kamer
        </RoomLabel>
        <RoomPrice>{Math.ceil(price.discounted)} € per/p</RoomPrice>
        <StyledButton
          color="#F83600"
          onClick={() => removeRoom(id)}
          /* @ts-ignore */
          disabled={rooms?.length <= 1}
          $disabled={rooms?.length <= 1}
        >
          <TrashIcon />
        </StyledButton>
      </RoomContent>
      <CardLine />
    </>
  );
};

type RoomOptionType = {
  value: number;
  label: string;
};

type RoomCardProps = {
  setLoading: (arg: boolean) => void;
  rooms: RoomType[];
  roomOptions: RoomOptionType[];
  prices: PriceType[];
  booking: any;
};

const RoomCard = ({
  setLoading,
  rooms,
  roomOptions,
  prices,
  booking,
}: RoomCardProps) => {
  const {
    appState: { brand, bookingId },
  } = useAppState() as ContextType;
  const [addRoom] = useMutation(ADD_ROOM);
  const [removeRoom] = useMutation(REMOVE_ROOM);
  const [initTravellers] = useMutation(INIT_TRAVELLERS);
  const [removeTraveller] = useMutation(REMOVE_TRAVELLER);
  const [selectedRoomSize, setSelectedRoomSize] = useState<number>();

  const removeTravellerMutation = async (
    travellerToRemove: any,
    filteredTravellers: any,
    _rooms: any
  ) => {
    await removeTraveller({
      variables: {
        id: travellerToRemove.id,
      },
      update(cache, { data: { removeTraveller } }) {
        if (removeTraveller.success) {
          cache.writeFragment({
            id: `Booking:${bookingId}`,
            fragment: gql`
              fragment MyRemoveBooking on Booking {
                travellers
                rooms
              }
            `,
            data: {
              __typename: "Booking",
              travellers: filteredTravellers,
              rooms: _rooms,
            },
          });
        } else {
          console.error("failed to remove room");
        }
      },
    });
  };

  const remove = async (id: string) => {
    setLoading(true);

    await removeRoom({
      variables: {
        id,
      },
      update(cache, { data: { removeRoom } }) {
        if (removeRoom.success) {
          const removeTravellers = async () => {
            let _rooms = JSON.parse(JSON.stringify(rooms));
            _rooms = _rooms.filter((x: RoomType) => x.id !== id);

            const travellersLeft = _rooms?.reduce((acc: any, r: any) => {
              return acc + (r.size + 1);
            }, 0);

            const manipulateTravellersInBooking = [...booking?.travellers];
            const filteredTravellers = manipulateTravellersInBooking?.splice(
              0,
              travellersLeft
            );

            const travellersToRemove =
              JSON.parse(JSON.stringify(rooms))?.filter(
                (x: RoomType) => x.id === id
              )?.[0].size + 1;

            for (let i = 1; i <= travellersToRemove; i++) {
              const toRemoveIndex = booking?.travellers?.length - i;
              const travellerToRemove = booking?.travellers[toRemoveIndex];
              await removeTravellerMutation(
                travellerToRemove,
                filteredTravellers,
                _rooms
              );
            }
          };
          removeTravellers();
          setLoading(false);
        } else {
          console.error("failed to remove room");
        }
      },
    });
  };

  const add = async () => {
    setLoading(true);

    const newRoom = {
      size: (selectedRoomSize as number) - 1,
      booking: bookingId,
      cost: 0,
      price: prices[(selectedRoomSize as number) - 1].discounted,
    };

    await addRoom({
      variables: {
        input: {
          ...newRoom,
        },
      },
      update(cache, { data: { addRoom } }) {
        if (addRoom.success) {
          const addTravellers = async () => {
            let _rooms = JSON.parse(JSON.stringify(rooms));
            _rooms.push({
              __typename: "Room",
              size: newRoom.size,
              id: addRoom.id,
              price: prices[(selectedRoomSize as number) - 1].discounted,
            });

            const travellersToAdd = newRoom.size + 1;

            await initTravellers({
              variables: {
                count: travellersToAdd,
                bookingId,
              },
              update(cache, { data: { initTravellers } }) {
                if (initTravellers.success) {
                  let travellers = booking?.travellers || [];
                  travellers = [...travellers, ...initTravellers.data];

                  initTravellers?.data?.forEach((traveller: TravellerType) => {
                    cache.writeFragment({
                      id: `Traveller:${traveller.id}`,
                      fragment: gql`
                        fragment MyUpdateTraveller on Traveller {
                          ${Object.keys(traveller)}
                        }
                        `,
                      data: {
                        __typename: "Traveller",
                        ...traveller,
                      },
                    });
                  });

                  cache.writeFragment({
                    id: `Booking:${bookingId}`,
                    fragment: gql`
                      fragment MyAddBooking on Booking {
                        travellers
                        rooms
                      }
                    `,
                    data: {
                      __typename: "Booking",
                      travellers: travellers,
                      rooms: _rooms,
                    },
                  });
                } else {
                  console.error("failed to add travellers");
                }
              },
            });
          };
          addTravellers();
          setLoading(false);
        } else {
          console.error("failed to create room");
        }
      },
    });
  };

  return (
    <Card>
      <Header>Kies je kamer(s)</Header>
      {rooms?.map((room) => (
        <Room
          id={room.id}
          key={room.id}
          size={room.size}
          price={prices[room.size]}
          removeRoom={remove}
          rooms={rooms}
        />
      ))}
      <AddRoomContainer>
        <div>
          Kies een kamer:* <br />
          <small>
            *Wil jij een extra kamer toevoegen aan je reservatie? Klik hier.
          </small>
        </div>
        <StyledDropdown
          handleChange={(size: number) => setSelectedRoomSize(Number(size))}
          value={selectedRoomSize}
          options={roomOptions}
        />
        {selectedRoomSize ? (
          <Button onClick={add} color={brand.color}>
            Toevoegen
          </Button>
        ) : (
          <Button color="#655C7E">Toevoegen</Button>
        )}
      </AddRoomContainer>
    </Card>
  );
};

export default RoomCard;
