import React from "react";
import { StyledSelect } from "./dropdown.styled";

type OptionType = {
  value: any;
  label: string;
  disabled?: boolean;
};

type DropdownProps = {
  options: OptionType[];
  value?: any;
  handleChange: (arg: any) => void;
  name?: string;
  className?: string;
};

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  value,
  handleChange,
  name,
  className,
}) => {
  let _value = value;
  if (typeof value !== "object") {
    _value = options.filter((x) => x.value === value)[0];
  }
  if (Array.isArray(value)) {
    _value = [];
    options.forEach((x) => {
      if (value.includes(x.value)) {
        _value.push(x);
      }
    });
  }
  return (
    <StyledSelect
      name={name}
      value={value}
      className={className}
      onChange={(e) => handleChange(e.target.value)}
    >
      <option></option>
      {options.map((o) => (
        <option disabled={o.disabled} key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </StyledSelect>
  );
};
