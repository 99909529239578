import React, { Fragment } from "react";
import {
  Container,
  Item,
  Line,
  ActiveIcon,
  InactiveIcon,
} from "./Steps.styled";
import SunSVG from "./Sun";
import MountainSVG from "./Mountain";
import { ContextType, useAppState } from "../../store";
import { UseGetBooking } from "../../hooks/UseGetBooking";

const steps = (targetAudienceEnabled: boolean) => [
  {
    name: "Start met boeken",
    disableIf: () => null,
  },
  {
    name: targetAudienceEnabled
      ? "Kies je kamers & doelgroep"
      : "Kies je kamers",
    disableIf: () => null,
  },
  {
    name: "Gegevens invullen",
    disableIf: () => null,
  },
  {
    name: "Kamer indeling kiezen",
    disableIf: () => null,
  },
  {
    name: "Reis details",
    disableIf: (booking: BookingType) =>
      booking?.transport !== "plane" ||
      booking?.brandAccommodation?.brandDestination?.airports?.length === 0,
  },
  {
    name: "Verzekeringen & excursies",
    disableIf: () => null,
  },
  {
    name: "Extras kiezen",
    disableIf: (booking: BookingType) =>
      booking?.brandAccommodation?.brandDestination?.addons?.length === 0,
  },
  {
    name: "Bevestig jouw reis.",
    disableIf: () => null,
  },
];

export const GetNextValidStep = (booking: BookingType, currentStep: number) => {
  const _steps = steps(false);
  let nextStep = null;
  let nextStepToTry = currentStep;
  while (!nextStep) {
    const _step = _steps[nextStepToTry];
    if (!_step.disableIf(booking)) {
      nextStep = nextStepToTry;
    } else {
      nextStepToTry++;
    }
  }
  return nextStep + 1;
};

export const GetPreviousValidStep = (
  booking: BookingType,
  currentStep: number
) => {
  const _steps = steps(false);
  let previousStep = null;
  let previousStepToTry = currentStep - 2;

  while (!previousStep) {
    const _step = _steps[previousStepToTry];
    if (!_step.disableIf(booking)) {
      previousStep = previousStepToTry;
    } else {
      previousStepToTry--;
    }
  }
  return previousStep + 1;
};

type StepProps = {
  isActive: boolean;
  name: string;
  completed: boolean;
  last: boolean;
  disable: boolean;
};

const Step: React.FC<StepProps> = ({
  isActive,
  disable,
  completed,
  name,
  last,
}) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  const CompletedIcon = brand.code.includes("sb") ? SunSVG : MountainSVG;
  if (disable) {
    return null;
  }
  return (
    <Fragment>
      <Item $isActive={isActive}>
        {completed && <CompletedIcon />}
        {isActive && <ActiveIcon color={brand.color} />}
        {!isActive && !completed && <InactiveIcon />}
        <span>{name}</span>
      </Item>
      {!last && <Line color={brand?.color} $isActive={isActive} />}
    </Fragment>
  );
};

export const Steps = ({ current = 0 }) => {
  const {
    appState: { brand, bookingId },
  } = useAppState() as ContextType;
  const { booking, loading } = UseGetBooking(bookingId);
  const { targetAudienceEnabled } = brand.features;
  const _steps = steps(targetAudienceEnabled);
  if (loading) {
    return null;
  }
  return (
    <Container>
      {_steps.map((s, i) => (
        <Step
          key={s.name}
          disable={!!s.disableIf(booking)}
          last={i === _steps.length - 1}
          isActive={current === i}
          name={s.name}
          completed={i < current}
        />
      ))}
    </Container>
  );
};
