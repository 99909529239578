import styled from "styled-components";

export const TravellerHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #f8f8f9;
  width: 100%;
  padding: 24px 15px;
  display: flex;
  justify-content: space-between;
`;

export const TravellerContent = styled.div`
  padding: 16px 20px;
`;

export const TravellerObject = styled.div`
  width: 100%;
`;

export const TextBlock = styled.div`
  color: #231747;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: capitalize;
`;

export const CardLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background-color: rgb(138 144 144 / 84%);
  margin: 41px 0;
`;

export const Title = styled.div`
  color: #231747;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 20px;
`;
