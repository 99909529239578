import React from "react";
import InputMask from "react-input-mask";
import { StyledInput } from "./DateInput.styled";

type Props = {
  handleChange: (arg: string) => void;
  name: string;
  value: string;
};

export const DateInput = ({ handleChange, name, value }: Props) => {
  return (
    <StyledInput>
      <InputMask
        name={name}
        mask={"99/99/9999"}
        placeholder={"dd/mm/yyyy"}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
    </StyledInput>
  );
};
