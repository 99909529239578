import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RadioButton = styled.div<{ $active: boolean }>`
  height: 24px;
  width: 24px;
  border: ${({ $active }) =>
    $active ? "2px solid #231747" : "1.5px solid #dedce3"};
  border-radius: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioButtonActiveDot = styled.div`
  height: 14px;
  width: 14px;
  background-color: #231747;
  border-radius: 14px;
`;

export const Label = styled.div`
  color: #093949;
  font-size: 16px;
  text-transform: Capitalize;
  letter-spacing: 0.23px;
  line-height: 19p;
`;

export const RadioButtonField = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :not(:last-child) {
    margin-right: 20px;
  }
`;
