import React from "react";
import { Helmet } from "react-helmet";
import { Container, StyledImage } from "./Header.styled";
import { ContextType, useAppState } from "../../store";
import { getImageFromServer, getFaviconFromServer } from "../../helpers";

export const Header = () => {
  const {
    appState: { brand, favicon, logoWithText },
  } = useAppState() as ContextType;

  return (
    <Container>
      <Helmet>
        <title>{brand.name} | Booking</title>
        <link rel="icon" href={getFaviconFromServer(favicon, 16)} />
      </Helmet>
      <StyledImage
        src={getImageFromServer(logoWithText, "16:9", 400)}
        alt={brand.name}
      />
    </Container>
  );
};
