import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { theme } from "@travelquest/web-components";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import * as Sentry from "@sentry/react";

import { Store } from "./store";
import App from "./App";
import "./i18n";

// Get the brand from the url first
let gqlURL = process.env.REACT_APP_GQL_ENDPOINT;
Sentry.init({
  dsn: "https://0747334bb16c4e0c8e34da08c3adc75f@o120610.ingest.sentry.io/5566829",
  autoSessionTracking: true,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const httpLink = ApolloLink.from([new HttpLink({ uri: gqlURL })]);

export const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    TargetAudience: { merge: true },
  },
});
const client = new ApolloClient({
  cache,
  link: httpLink,
});

const GlobalStyle = createGlobalStyle`
  html { overflow:auto; height: 100%; }
  body {
    margin: 0;
    width: 100vw;
    background-color: #F8F8F9;
    height: 100%;
    font-family: ${theme.fontFamily};
    overflow-x: hidden;
  }
  div {
    box-sizing: border-box;
  }
`;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <Store>
        <GlobalStyle />
        <Router>
          <App />
        </Router>
      </Store>
    </ApolloProvider>
  </ThemeProvider>
);
