import styled from "styled-components";
import { CheckmarkIcon } from "@travelquest/web-components";

export const TravellerHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  cursor: pointer;
  background-color: #f8f8f9;
  width: 100%;
  padding: 24px 15px;
  display: flex;
  justify-content: space-between;
`;

export const TravelerContent = styled.div`
  padding: 16px 20px;
`;

export const TravellerObject = styled.div`
  width: 100%;
`;

export const ValidIcon = styled(CheckmarkIcon)`
  color: green;
  margin-left: 10px;
`;
