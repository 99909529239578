import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Button } from "@travelquest/web-components";
import { useDebouncedCallback } from "use-debounce";
import { UseUpdateBookingHook } from "../../hooks/UseUpdateBookingHook";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { useAppState, ContextType } from "../../store";
import {
  Steps,
  Loader,
  Card,
  Page,
  BookingInformation,
  CardLine,
} from "../../components";
import RoomCard from "./RoomCard";
import {
  Marker,
  MarkerActiveDot,
  Header,
  RoomContent,
  Buttons,
  Layout,
} from "./ChooseRooms.styled";
import { useQuery } from "@apollo/client";
import { GET_TARGETAUDIENCES } from "../../queries/booking";

export const ChooseRooms = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;

  const { data, loading: audiencesLoading } = useQuery(GET_TARGETAUDIENCES, {
    variables: { brand: brand?.code },
  });
  let targetAudienceOptions = data?.targetAudiences;
  const { targetAudienceEnabled } = brand.features;
  const { booking, loading } = UseGetBooking(bookingId);
  const { updateBooking } = UseUpdateBookingHook();
  const [targetAudience, setTargetAudience] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);
  const skipStep = !!booking?.uniqueRoomId && !targetAudienceEnabled;
  if (booking?.brandAccommodation?.targetAudiences?.length) {
    targetAudienceOptions = booking?.brandAccommodation?.targetAudiences;
  }

  useEffect(() => {
    if (booking && targetAudienceOptions) {
      setTargetAudience(
        booking?.targetAudience?.id || targetAudienceOptions?.[0]?.id
      );
    }
  }, [booking, targetAudienceOptions, targetAudienceEnabled]);

  const debounced = useDebouncedCallback((values) => {
    updateBooking({ id: bookingId, values });
  }, 400);

  const changeAudience = useCallback(
    (targetAudience: TargetAudience) => {
      setTargetAudience(targetAudience.id);
      debounced({ targetAudience });
    },
    [debounced]
  );

  useEffect(() => {
    if (
      targetAudienceEnabled &&
      targetAudience === "" &&
      targetAudienceOptions
    ) {
      changeAudience(targetAudienceOptions?.[0]);
    }
  }, [
    targetAudienceOptions,
    targetAudienceEnabled,
    changeAudience,
    targetAudience,
  ]);

  const accommodation = booking?.brandAccommodation?.accommodation;

  const prices =
    booking?.period?.prices?.[booking?.mealType as keyof PeriodPriceType]?.[
      booking?.transport
    ] || [];

  const roomOptions = [];

  for (
    let i = accommodation?.roomSize?.min;
    i < accommodation?.roomSize?.max + 1;
    i++
  ) {
    const price = Math.ceil(prices?.[i - 1]?.discounted);
    if (price !== 0) {
      roomOptions.push({
        value: i,
        label: `${i}-persoonskamer - ${Math.ceil(
          prices?.[i - 1]?.discounted
        )}€ per/p`,
      });
    }
  }

  if (loading || !bookingId || audiencesLoading) {
    return <Loader />;
  }

  return (
    <Page>
      <Steps current={1} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <div>
          {!booking?.uniqueRoomId && (
            <RoomCard
              setLoading={setBusy}
              rooms={booking.rooms}
              prices={prices}
              roomOptions={roomOptions}
              booking={booking}
            />
          )}
          {targetAudienceEnabled && (
            <Card>
              <Header>Kies je doelgroep</Header>
              {targetAudienceOptions?.map((x: TargetAudience, i: number) => (
                <Fragment key={x.id}>
                  <RoomContent
                    onClick={() => changeAudience(x)}
                    style={{
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                  >
                    <Marker $active={targetAudience === x.id}>
                      {targetAudience === x.id && <MarkerActiveDot />}
                    </Marker>
                    <div>{x.name.nlbe}</div>
                  </RoomContent>
                  <CardLine />
                </Fragment>
              ))}
            </Card>
          )}

          <Buttons>
            {skipStep && (
              <div>
                Deze stap is voor jou reeds ingevuld. Wij zijn dus al op de
                hoogte van je doelgroep en gekozen accommodatie. Druk op de
                volgende stap om verder te gaan.
              </div>
            )}
            {targetAudience !== "" && !busy ? (
              <a href={`/step3?id=${bookingId}&brand=${brand.code}`}>
                <Button color={brand.color}>Volgende stap</Button>
              </a>
            ) : (
              <Button color="#655C7E">Volgende stap</Button>
            )}
          </Buttons>
        </div>
      </Layout>
    </Page>
  );
};
