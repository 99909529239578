const support_format_webp = () => {
  var elem = document.createElement("canvas");

  if (!!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
};

export const getImageFromServer = (
  image: string,
  aspectRatio: "16:9" | "9:16" | "21:9" | "1:1" | "5:7",
  size: 400 | 800 | 1080
): any => {
  const aspectRatioMap = {
    "16:9": "Landscape",
    "9:16": "Portrait",
    "21:9": "Panorama",
    "1:1": "Square",
    "5:7": "Card",
  };

  if (!image) {
    return null;
  }

  if (image.includes("http")) {
    return image;
  }
  let imageName = image;
  //remove the extension from the image
  if (imageName.split(".").length > 1) {
    imageName = imageName.split(".")[0];
  }
  const extension =
    typeof window !== "undefined" && support_format_webp() ? "webp" : "jpeg";

  if (image && size && aspectRatio) {
    return `${process.env.REACT_APP_IMG_SERVER_ROOT}/${imageName}-${aspectRatioMap[aspectRatio]}${size}.${extension}`;
  }
};

export const getFaviconFromServer = (
  name: string,
  size: 16 | 32 | 64 | 128 | 180
): any => {
  if (!name) {
    return null;
  }

  let imageName = name;
  //remove the extension from the image
  if (imageName.split(".").length > 1) {
    imageName = imageName.split(".")[0];
  }
  if (name && size) {
    return `${process.env.REACT_APP_IMG_SERVER_ROOT}/${imageName}-favicon-${size}.ico`;
  }
};
