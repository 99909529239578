import { gql } from "@apollo/client";

export const ADD_ROOM = gql`
  mutation addRoom($input: RoomInput!) {
    addRoom(input: $input) {
      message
      id
      success
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      message
      success
    }
  }
`;

export const REMOVE_ROOM = gql`
  mutation removeRoom($id: String!) {
    removeRoom(id: $id) {
      message
      success
    }
  }
`;
