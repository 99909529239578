import styled from "styled-components";
import { media } from "../../helpers/mediaQueries";

export const Container = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet`
  height: 100px;
  margin-bottom: 10px;
  `};
`;

export const StyledImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
`;
