export const transportOptions: Record<string, string> = {
  self: "Eigen vervoer",
  plane: "Vliegtuig",
  bus: "Bus",
};

export const mealTypes: Record<string, string> = {
  logies: "Logies",
  breakfast: "Breakfast",
  halfPension: "Half pension",
  fullPension: "Full pension",
  allInclusive: "All-inclusive",
  allInclusiveAllDay: "24hour all-inclusive",
};
