import styled from "styled-components";
import { Button } from "@travelquest/web-components";

import { media } from "../../helpers";

export const Layout = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 3fr 5fr;
  ${media.tablet`
  grid-template-columns: 1fr;
`}
`;

export const Header = styled.div`
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding: 20px;
  background-color: #231747;
`;

export const StyledConfirmButton = styled(Button)`
  ${media.tablet`
  margin-bottom: 20px;
`}
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  ${media.tablet`
  flex-direction: column-reverse;
  align-items: center;
`}
`;

export const Disclaimer = styled.div`
  padding: 16px 20px;
  color: #231747;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const PdfImage = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const PDFlink = styled.a`
  display: flex;
  align-items: center;
  color: #231747;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25.89px;
  margin-bottom: 20px;
`;

export const TermsAndConditions = styled.div`
  width: 100%;
  padding: 16px 20px;
`;
