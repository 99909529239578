import React, { useReducer, useContext, createContext, ReactNode } from "react";

const initialState = () => ({
  brand: null,
  bookingId: null,
  favicon: null,
  logo: null,
  logoWithText: null,
  trackingCode: null,
});

export type ContextType = {
  appState: AppStateType;
  appStateDispatch: Function;
};

type AppStateType = {
  brand: Brand;
  bookingId: string;
  favicon: string;
  logo: string;
  logoWithText: string;
  trackingCode: string;
};

type ReducerPayload = {
  type: "setInitialData" | "updateBookingData";
  payload: any;
};

function reducer(state: AppStateType, { type, payload }: ReducerPayload) {
  switch (type) {
    case "setInitialData":
      return {
        ...state,
        favicon: payload.favicon,
        bookingId: payload.bookingId,
        brand: payload.brand,
        logo: payload.logo,
        logoWithText: payload.logoWithText,
        trackingCode: payload.trackingCode,
      };
    case "updateBookingData":
      return { ...state };
    default:
      throw new Error(`Invalid action '${type}'.`);
  }
}

type initialDataPayload = {
  brand: Brand;
  bookingId: string;
  favicon: string;
  logo: string;
  logoWithText: string;
  trackingCode: string;
};
export const setInitialData = (payload: initialDataPayload) => ({
  type: "setInitialData",
  payload,
});
export const updateBookingData = (payload: any) => ({
  type: "updateBookingData",
  payload,
});
export const appStateContext = createContext<ContextType | null>(null);
export const AppStateProvider = appStateContext.Provider;
export const useAppState = () => {
  const ctx = useContext(appStateContext);
  return ctx;
};

type StoreProps = {
  children: ReactNode;
};

export const Store: React.FC<StoreProps> = ({ children }) => {
  const [appState, appStateDispatch] = useReducer(reducer, initialState());

  return (
    <appStateContext.Provider value={{ appState, appStateDispatch }}>
      {children}
    </appStateContext.Provider>
  );
};
