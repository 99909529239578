import React from "react";
import { Button } from "@travelquest/web-components";
import { ContextType, useAppState } from "../../store";
import { Steps, Page } from "../../components";
import StartImage from "../../images/start.png";
import { StyledImage, Text } from "./Introduction.styled";

export const Introduction = () => {
  const {
    appState: { brand, bookingId },
  } = useAppState() as ContextType;

  return (
    <Page>
      <Steps current={0} />
      <StyledImage src={StartImage} alt="start" />
      <Text>
        {`Ready to make it legendary? Via de volgende 5 stappen kan jij jouw
         ${brand?.name} reis reserveren. We raden je aan om je reis te
        reserveren via een computer en niet via je telefoon.`}
      </Text>
      <a href={`/step2?id=${bookingId}&brand=${brand?.code}`}>
        <Button color={brand?.color}>Start</Button>
      </a>
    </Page>
  );
};
