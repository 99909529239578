import React, { useState } from "react";
import { Button } from "@travelquest/web-components";
import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
  GetPreviousValidStep,
} from "../../components";
import PdfSVG from "../../images/pdf.svg";
import { calculateTravelInsurance } from "../../helpers";
import {
  Layout,
  Header,
  TermsAndConditions,
  StyledConfirmButton,
  Buttons,
  Disclaimer,
  PDFlink,
  PdfImage,
} from "./Confirmation.styled";
import { Checkbox } from "./Checkbox";
import { Traveller } from "./Traveller";
import { UseGetBooking } from "../../hooks/UseGetBooking";

const mapById = (addons: AddonType[]) => {
  const res: Record<string, AddonType> = {};
  addons?.forEach((e) => {
    res[e.id] = e;
  });
  return res;
};

export const Confirmation = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;
  const { booking, loading } = UseGetBooking(bookingId);

  const [openTraveller, setOpenTraveller] = useState<number | null>(0);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  if (!booking) {
    return <Loader />;
  }

  const addons = mapById(booking?.brandAccommodation?.brandDestination?.addons);
  const roomsById: Record<string, RoomType> = {};
  booking.rooms.forEach((r) => {
    roomsById[r.id] = r;
  });
  const prices =
    booking?.period?.prices?.[booking?.mealType]?.[booking?.transport];
  const travelInsurancePrice = calculateTravelInsurance(
    booking.period,
    booking.transport
  );

  const previousStep = GetPreviousValidStep(booking, 8);

  return (
    <Page>
      <Steps current={5} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <div>
          <Card>
            <Header>Bevestig je reservatie</Header>
            {booking.travellers.map((t, i) => (
              <Traveller
                room={roomsById?.[t?.room]?.size}
                basePrice={prices?.[roomsById?.[t.room]?.size]}
                travelInsurancePrice={travelInsurancePrice}
                addons={addons}
                isOpen={openTraveller === i}
                setIsOpen={setOpenTraveller}
                traveller={t}
                key={t.id}
                index={i}
                period={booking?.period}
              />
            ))}
          </Card>
          <Card>
            <Header>Voorwaarden en informatie</Header>
            <TermsAndConditions>
              <PDFlink
                href="https://legal.summerbash.be/alg_voorwaarden_summer_bash_events_SBBE.pdf"
                target="_blank"
              >
                <PdfImage src={PdfSVG} alt="PDF ICON" />
                Algemene voorwaarden Summer Bash
              </PDFlink>
              <Checkbox
                checked={hasAgreed}
                onClick={() => setHasAgreed(!hasAgreed)}
              >
                De hoofdboeker verklaart zich akkoord met de algemene
                voorwaarden van Summer Bash Events en dat alle gegevens van
                zowel de hoofdboeker als zijn/haar medereizigers correct werden
                ingevuld.
              </Checkbox>
            </TermsAndConditions>
          </Card>
          <Card>
            <Header>Bevestig je reis</Header>
            <Disclaimer>
              <p>
                Je bent er bijna, eens je de reis bevestigd, is jouw reis
                geboekt. Kijk dus zeker of alle bovenstaande gegevens correct
                zijn ingevuld.
              </p>
              <p>
                <b>OPGELET</b>: Zodra je de reis bevestigd, kan je niet meer
                kosteloos annuleren. Je hebt dus geen recht op het
                herroepingsrecht bij het bevestigen van je pakketreis. Iedere
                reiziger (en bij minderjarigen ook de
                ouder/voogd/verantwoordelijke) ontvangt automatisch een
                bevestigingsmail met het boekingsoverzicht.
              </p>
              <Checkbox
                checked={hasConfirmed}
                onClick={() => setHasConfirmed(!hasConfirmed)}
              >
                Ik bevestig mijn reis.
              </Checkbox>
            </Disclaimer>
          </Card>
          <Buttons>
            <a
              href={`/step${previousStep}?id=${bookingId}&brand=${brand.code}`}
            >
              <Button color="#655C7E">Vorige stap</Button>
            </a>
            {hasAgreed && hasConfirmed ? (
              <a href={`/step9?id=${bookingId}&brand=${brand.code}`}>
                <StyledConfirmButton color={brand.color}>
                  Bevestig je reservatie
                </StyledConfirmButton>
              </a>
            ) : (
              <StyledConfirmButton color="#655C7E">
                Bevestig je reservatie
              </StyledConfirmButton>
            )}
          </Buttons>
        </div>
      </Layout>
    </Page>
  );
};
