import { gql } from "@apollo/client";
import { languageObject } from "./language";

export const GET_COMBO_DEALS = gql`
  query comboDeals($brand: String!, $brandDestination: String!) {
    comboDeals(brand: $brand, brandDestination: $brandDestination) {
      id
      name {
        ${languageObject}
      }
      bookable
      excursions {
        id
        name {
          ${languageObject}
        }
      }
      price
      brandDestination {
        id
        destination {
          id
          name {
            ${languageObject}
          }
        }
      }
    }
  }
`;
