import React from "react";
import {
  Container,
  RadioButton,
  RadioButtonField,
  RadioButtonActiveDot,
  Label,
} from "./RadioButtons.styled";

const translations: Record<string, string> = {
  male: "Man",
  female: "Vrouw",
  other: "Andere",
};

type Props = {
  value: any;
  handleChange: (arg: string) => void;
  options: string[];
};

export const RadioButtons = ({ options, value, handleChange }: Props) => {
  return (
    <Container>
      {options?.map((o, i) => (
        <RadioButtonField key={o} onClick={() => handleChange(o)}>
          <RadioButton $active={value === o}>
            {value === o && <RadioButtonActiveDot />}
          </RadioButton>
          <Label>{translations[o]}</Label>
        </RadioButtonField>
      ))}
    </Container>
  );
};
