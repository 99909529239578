import React, { ReactNode } from "react";
import { Container, Content } from "./Page.styled";
import { Header } from "../Header";

type PageProps = {
  children: ReactNode;
};

export const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <Container>
      <Header />
      <Content>{children}</Content>
    </Container>
  );
};
