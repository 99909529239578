import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledTitle = styled.div`
  color: #231747;
  font-weight: 500;
  font-size: 20px;
`;

export const StyledDescription = styled.div`
  color: white;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
`;

export const StyledSmallDescription = styled.div`
  color: white;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
`;

export const StyledLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: #231747;
  }
`;

export const TopPart = styled.div`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: white;
  padding: 10px;
`;

export const BottomPart = styled.div`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #c30045;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledLogo = styled.img`
  position: absolute;
  top: 10px;
  right: 0px;
  width: 100px;
  height: 50px;
  aspect-ratio: 1/2;
`;
