import styled from "styled-components";
import { Dropdown } from "../../../components";
import { Button } from "@travelquest/web-components";
import { media } from "../../../helpers/mediaQueries";

export const RoomCount = styled.span`
  padding: 10px;
  border: 1.5px solid #dedce3;
  border-radius: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoomMultiplierIcon = styled.span`
  padding: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const RoomLabel = styled.div`
  display: flex;
  align-items: center;
`;
export const RoomPrice = styled.div``;
export const AddRoomContainer = styled.div`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  small {
    font-size: 12px;
  }
  span {
    color: #093949;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.26px;
    line-height: 22px;
    text-align: right;
  }
  ${media.tablet`
    flex-direction: column;
  `}
`;

export const RoomContent = styled.div`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #093949;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.26px;
    line-height: 22px;
    text-align: right;
  }
`;

export const Header = styled.div`
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding: 20px;
  background-color: #231747;
`;

export const StyledButton = styled(Button)<{ $disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  min-width: 30px !important;
  max-width: 30px !important;
  padding: 0;
  height: 30px;

  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
`;

export const StyledDropdown = styled(Dropdown)`
  margin-right: 20px;
  ${media.tablet`
  margin: 10px 0;
`}
`;
