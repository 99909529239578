import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  SEND_CONFIRMATION_EMAIL,
  SEND_INVOICE_EMAIL,
  UPDATE_BOOKING,
} from "../../queries/booking";
import { Page, Loader } from "../../components";
import StartImage from "../../images/start.png";
import { ContextType, useAppState } from "../../store";
import { StyledButton, StyledImage, Title, Text } from "./Finish.styled";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import {
  calculateAge,
  calculateTravelInsurance,
  getPriceForAddon,
} from "../../helpers";
import { getPriceForExcursion } from "../../helpers/getPriceForExcursion";
import TagManager from "react-gtm-module";
import dayjs from "dayjs";
import { calculateTotalPrice } from "../../components/BookingInformation/calculateTotalPrice";
import { calculateInsurances } from "../../components/BookingInformation/calculateInsurances";
import { calculateAnnulationInsurance } from "../../components/BookingInformation/calculateAnnulationInsurance";
import { calculateAddons } from "../../components/BookingInformation/calculateAddons";
import { calculateExcursions } from "../../components/BookingInformation/calculateExcursions";

export const Finish = () => {
  const [done, setDone] = useState(false);
  const {
    appState: { bookingId, brand, trackingCode },
  } = useAppState() as ContextType;
  const { booking } = UseGetBooking(bookingId);
  const [sendInvoiceEmail] = useMutation(SEND_INVOICE_EMAIL);
  const [sendConfirmationEmail] = useMutation(SEND_CONFIRMATION_EMAIL);
  const [updateBooking] = useMutation(UPDATE_BOOKING);
  useEffect(() => {
    //@ts-ignore
    if (trackingCode && window.dataLayer && booking?.status === "incomplete") {
      const insurances = calculateInsurances(
        booking.travellers || [],
        booking?.rooms
      );
      const travelInsurancePrice = calculateTravelInsurance(
        booking.period,
        booking.transport
      );
      const prices =
        booking?.period?.prices?.[booking?.mealType]?.[booking?.transport];
      const annulationInsurance = calculateAnnulationInsurance(
        prices[booking?.rooms[0].size].discounted
      );
      const total = calculateTotalPrice(
        booking?.rooms,
        prices,
        insurances.travelInsurances,
        travelInsurancePrice
      );
      const excursionTotals = calculateExcursions(booking?.travellers);

      const addonTotals = calculateAddons(
        booking?.travellers,
        booking?.brandAccommodation?.brandDestination?.addons,
        booking?.period?.start
      );

      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: booking?.id,
            currency: "euro",
            value:
              total +
              insurances.annulationInsurances * annulationInsurance +
              excursionTotals.price +
              addonTotals.price,
            items: [
              {
                period: `${dayjs(booking?.period.start * 1000).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(booking?.period.end * 1000).format("DD/MM/YYYY")}`,
                travellers: booking?.travellers?.length || 0,
                price:
                  total +
                  insurances.annulationInsurances * annulationInsurance +
                  excursionTotals.price +
                  addonTotals.price,
                item_id: booking?.brandAccommodation?.brandDestination?.id,
                item_name:
                  booking?.brandAccommodation?.brandDestination?.destination
                    ?.name?.nlbe || "unknown",
              },
            ],
          },
        },
      });
    }
  }, [trackingCode, booking]);

  useEffect(() => {
    if (booking && !done) {
      const emails: string[] = [];
      booking.travellers.forEach((x) => {
        emails.push(x.email);
        if (x.extraEmail && x.extraEmail !== "") {
          emails.push(x.extraEmail);
        }
        if (calculateAge(x.dateOfBirth) < 18) {
          emails.push(x.parentEmail as string);
        }
      });
      const formattedExcursions =
        booking?.brandAccommodation?.brandDestination?.excursions?.map((x) => ({
          id: x.id,
          price: getPriceForExcursion(
            x.pricing,
            x.defaultPrice,
            booking?.period?.start
          ),
          name: x.name.nlbe,
        }));

      const formattedAddons =
        booking?.brandAccommodation?.brandDestination?.addons?.map((x) => ({
          id: x.id,
          price: getPriceForAddon(
            x.pricing,
            x.defaultPrice,
            booking?.period?.start
          ),
          name: x.name.nlbe,
        }));

      updateBooking({
        variables: {
          input: {
            id: booking.id,
            status: "booked",
            pricingInformation: {
              insurances: {
                annulationInsurance: 0.041,
                travelInsurance: 2.5,
              },
              excursions: formattedExcursions,
              addons: formattedAddons || [],
            },
          },
        },
        update(_, { data: { updateBooking } }) {
          if (updateBooking.success) {
            sendConfirmationEmail({
              variables: {
                input: { emails, id: booking.id },
              },
              update(_, { data: { sendConfirmationEmail } }) {
                if (sendConfirmationEmail.success) {
                  // On demand bookings don't receive invoice emails

                  if (booking.onDemand) {
                    setDone(true);
                  } else {
                    sendInvoiceEmail({
                      variables: { id: booking.id },
                      update(_, { data: { sendInvoiceEmail } }) {
                        if (sendInvoiceEmail.success) {
                          setDone(true);
                        } else {
                          console.error(
                            "Something went wrong when sending the invoice email"
                          );
                        }
                      },
                    });
                  }
                }
              },
            });
          }
        },
      });
    }
  }, [booking, done, sendInvoiceEmail, sendConfirmationEmail, updateBooking]);

  if (!done) {
    return <Loader text="Even geduld..." />;
  }
  return (
    <Page>
      <StyledImage src={StartImage} alt="start" />
      <Title>Bedankt voor je boeking</Title>
      <Text>
        Zowel jij als jouw reisgenoten ontvangen zo dadelijk een e-mail met de
        bevestigingsmail en jouw bestelbon. Heb je deze niet ontvangen? Kijk dan
        zeker ook in je spam.
      </Text>
      <Text>Via volgende knop kan je inloggen in jouw online account:</Text>
      <a href={`https://travellers.${brand.url}`} style={{ width: "60%" }}>
        <StyledButton color={brand.color}>
          Ga naar je online account
        </StyledButton>
      </a>
    </Page>
  );
};
