import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
`;

export const Star = styled.div`
  display: inline-block;
  margin-right: 10px;
  color: #fac917;
`;

export const HalfStar = styled.div`
  display: inline-block;
  color: #fac917;
  width: 8px;
  overflow-x: hidden;
  margin-right: 18px;
`;
