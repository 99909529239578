import styled from "styled-components";
import {
  CircleEmptyIcon,
  CircleFilledIcon,
  theme,
} from "@travelquest/web-components";
import { media } from "../../helpers/mediaQueries";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
`;

export const Item = styled.div<{ $isActive: boolean }>`
  display: flex;
  color: #231747;

  align-items: center;
  flex-direction: column;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  max-width: 200px;
  svg {
    width: 30px;
    margin-bottom: 12px;
  }
  ${media.tablet`
  display: ${({ $isActive }: { $isActive: boolean }) =>
    $isActive ? "flex" : "none"};
  `};
`;

export const ActiveIcon = styled(CircleFilledIcon)`
  color: ${({ color }) => color};
  margin-bottom: 12px;
  ${media.tablet`
    display: none;
  `};
`;

export const InactiveIcon = styled(CircleEmptyIcon)`
  color: ${theme.greyLight};
  margin-bottom: 12px;
`;

export const Line = styled.div<{ $isActive: boolean }>`
  border: none;
  border-top: 1px dashed
    ${({ $isActive, color }) => ($isActive ? color : theme.greyLight)};
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100px;
  margin: 0 20px;
  margin-top: 10px;
  ${media.tablet`
    display: none;
  `};
`;
