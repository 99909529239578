import { gql } from "@apollo/client";

export const ADD_TRAVELLER_EXCURSION = gql`
  mutation addTravellerExcursion($input: TravellerExcursionInput!) {
    addTravellerExcursion(input: $input) {
      message
      id
      success
    }
  }
`;

export const REMOVE_TRAVELLER_EXCURSION = gql`
  mutation removeTravellerExcursion($id: String!) {
    removeTravellerExcursion(id: $id) {
      message
      success
    }
  }
`;
