import React from "react";
import InputMask from "react-input-mask";
import { StyledInput } from "./RegistryNumerInput.styled";

type Props = {
  isNL: boolean;
  name: string;
  value: string;
  handleChange: (arg: string) => void;
};

export const RegistryNumberInput = ({
  name,
  value,
  handleChange,
  isNL,
}: Props) => {
  if (isNL) {
    return (
      <StyledInput>
        <InputMask
          name={name}
          mask={"999999999"}
          placeholder={"xxxxxxxxx"}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      </StyledInput>
    );
  }
  return (
    <StyledInput>
      <InputMask
        name={name}
        mask={"99.99.99-999.99"}
        placeholder={"xx.xx.xx.-xxx.xx"}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
    </StyledInput>
  );
};
