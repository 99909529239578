import styled from "styled-components";

export const StyledSelect = styled.select`
  border: 1.5px solid #dedce3;
  border-radius: 10px;
  padding: 8px 16px;
  select {
    background-color: red;
    margin-right: 10px;
  }
`;
