import styled from "styled-components";
import { media } from "../../helpers/mediaQueries";

export const StyledImage = styled.img`
  width: 300px;
`;

export const Text = styled.div`
  margin-bottom: 30px;
  width: 50%;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  ${media.tablet`
  width: 100%;
`};
`;
