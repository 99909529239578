export const calculateRooms = (rooms: RoomType[]) => {
  const roomsObj: Record<string, number> = {};
  rooms.forEach((r) => {
    const key = `${r.size + 1}`;
    if (roomsObj[key]) {
      roomsObj[key]++;
    } else {
      roomsObj[key] = 1;
    }
  });
  return roomsObj;
};
