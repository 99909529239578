import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  TravelerContent,
  TravellerHeader,
  TravellerObject,
  ValidIcon,
} from "./Traveller.styled";
import { MinIcon, PlusMathIcon } from "@travelquest/web-components";
import { TravellerForm } from "../../../components";

type TravellerProps = {
  index: number;
  isOpen: boolean;
  openHandler: (arg: number | null) => void;
  defaultValues: any;
  valid: boolean;
  nextTraveller: (arg: number) => void;
  update: (arg: any) => void;
};

export const Traveller: React.FC<TravellerProps> = ({
  index,
  isOpen,
  openHandler,
  defaultValues,
  valid,
  nextTraveller,
  update,
}) => {
  const [formValues, setFormValues] = useState<any>(null);
  const debouncedUpdateTraveller = useDebouncedCallback((values) => {
    update(values);
  }, 2000);

  const callback = (values: any) => {
    setFormValues(values);
    debouncedUpdateTraveller(values);
  };

  return (
    <TravellerObject>
      <TravellerHeader
        onClick={() => {
          if (formValues) {
            update(formValues);
          }
          openHandler(!isOpen ? index : null);
        }}
      >
        <span>
          Reiziger {index + 1}
          {valid && <ValidIcon />}
        </span>
        {isOpen ? <MinIcon /> : <PlusMathIcon />}
      </TravellerHeader>
      {isOpen && (
        <TravelerContent>
          <TravellerForm
            defaultValues={defaultValues}
            nextTraveller={() => nextTraveller(index)}
            update={update}
            onValuesChangeCallback={callback}
          />
        </TravelerContent>
      )}
    </TravellerObject>
  );
};
